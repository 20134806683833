// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Button,
  styled,
  InputAdornment,
} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DepartmentController, {
  Props,
  images,
  configJSON,
} from "./DepartmentController.web";
import Layout from "../../../components/src/Layout.web";
import Loader from "../../../components/src/Loader.web";
import { InputBox, StyledCheckbox } from "./Agents.web";
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";
// Customizable Area End

export default class Department extends DepartmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Layout menuItem="manageDept" navigation={this.props.navigation}>
        <Loader loading={this.state.isLoading} />
        <MainContainer data-test-id="departmentComponent">
          <Box
            className="departmentHeader"
            style={{ background: this.state.selectedMode ? "white" : "" }}
          >
            <Box className="headerBox">
              <img src={images.group} width={64} height={48} />
              <Typography className="departmentHeaderTitle">
                {configJSON.listDepartment}
              </Typography>
              <InputBox
                placeholder="Search"
                type="text"
                data-test-id="searchText"
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ zIndex: 1, marginRight: 0 }}
                  >
                    <SearchRoundedIcon className="search" />
                  </InputAdornment>
                }
                value={this.state.search}
                onChange={(event: { target: { value: string } }) =>
                  this.handleSearch(event.target.value)
                }
              />
            </Box>
            {this.state.selectedMode ? (
              <Box style={{ display: "flex" }}>
                <Box
                  style={{
                    display: "flex",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <StyledCheckbox
                    data-test-id={"select_all"}
                    checked={this.state.selectAll}
                    onChange={(event, checked) =>
                      this.handleSelectAll(!checked)
                    }
                  />
                  <Box
                    data-test-id={"select_all_text"}
                    onClick={() => this.handleSelectAll(this.state.selectAll)}
                    style={{
                      fontWeight: 700,
                      color: "rgba(100, 116, 139, 1)",
                      fontSize: "16px",
                      cursor: "pointer",
                      paddingLeft: "7px",
                    }}
                  >
                    {!this.state.selectAll
                      ? configJSON.selectAll
                      : configJSON.unselectAll}
                  </Box>
                </Box>
                {this.state.selectedIds.length > 0 ? (
                  <Box
                    data-test-id="select_delete"
                    onClick={this.handleBulkDeleteConfirmationDialog}
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      fontFamily: "Poppins",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                    <DeleteOutlineRoundedIcon
                      style={{
                        color: "rgba(220, 38, 38, 1)",
                        marginRight: "5px",
                      }}
                    />
                    <Box
                      style={{
                        fontWeight: 700,
                        color: "rgba(220, 38, 38, 1)",
                        fontSize: "16px",
                      }}
                    >
                      {configJSON.delete}
                    </Box>
                  </Box>
                ) : null}
                <Box
                  data-test-id="cancel_all"
                  style={{
                    display: "flex",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                    fontWeight: 700,
                    marginLeft: "20px",
                    color: "rgba(217, 119, 6, 1)",
                    fontSize: "16px",
                  }}
                  onClick={() => this.handleSelectOption()}
                >
                  {configJSON.cancel}
                </Box>
              </Box>
            ) : null}
            <Button
              data-test-id="add-department"
              variant="contained"
              className="departmentHeaderButton"
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={this.handleAddDepartment}
              disabled={this.state.selectedMode}
            >
              {configJSON.addDepartment}
            </Button>
          </Box>
          {this.renderView()}
        </MainContainer>
        <DeleteConfirmationDialog
          open={this.state.bulkDeleteConfirmation}
          onDone={this.handleAllDelete}
          onClose={this.handleBulkDeleteConfirmationDialog}
        />
        <DeleteConfirmationDialog
          open={!!this.state.deleteConfirmationId}
          onDone={() =>
            this.handleDeleteDepartment(this.state.deleteConfirmationId)
          }
          onClose={() => this.handleDeleteConfirmationDialog(0)}
        />
      </Layout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(() => ({
  gap: "20px",
  display: "grid",
  "& .borderNone": {
    border: "none",
  },
  "& .headerBox": {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap",
  },
  "& .departmentHeader": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
    padding: "5px",
  },
  "& .departmentHeaderTitle": {
    color: "#281D19",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "32px",
  },
  "& .departmentHeaderButton": {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    borderRadius: 8,
    padding: "10px 16px",
    "&:disabled": {
      color: "#64748B",
      backgroundColor: "#F1F5F9",
      borderColor: "#F1F5F9",
    },
  },
  "& .departmentDataContainer": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  "& .departmentNoDataInnerContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: 10,
    margin: "75px 20px",
  },
  "& .noDataImage": {
    marginBottom: 30,
    width: 238,
    height: 232,
  },
  "& .departmentNoDataMainText": {
    fontWeight: 700,
    fontSize: 16,
  },
  "& .departmentNoDataSubText": {
    fontSize: 14,
  },
  "& .tableHeader": {
    color: "#334155",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "22px",
    maxWidth: "150px",
  },
  "& .tableRow": {
    color: "#0F172A",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "22px",
    maxWidth: "150px",
    wordBreak: "break-all",
  },
}));
// Customizable Area End
