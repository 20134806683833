import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link } from "react-router-dom";
import {
  SvgIcon,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Box
} from "@material-ui/core";
import {
  PermIdentity,
  Menu,
} from "@material-ui/icons";
// @ts-ignore
import { ReactComponent as HomeIcon } from "../assets/home_icon.svg";
// @ts-ignore
import { ReactComponent as BarIcon } from "../assets/bar_icon.svg";
// @ts-ignore
import { ReactComponent as HolidayIcon } from "../assets/holidays.svg";
// @ts-ignore
import { ReactComponent as DispositionIcon } from "../assets/disposition.svg";
// @ts-ignore
import { ReactComponent as TransferListIcon } from "../assets/transferList.svg";
// @ts-ignore
import { ReactComponent as DialIcon } from "../assets/dialOut.svg";
// @ts-ignore
import { ReactComponent as LocationIcon } from "../assets/locations.svg";
// @ts-ignore
import { ReactComponent as SettingIcon } from "../assets/settings.svg";
import { RoleType } from "../../../components/src/Utilities";
const images = require("../src/assets");

const drawerWidth = 120;

const useStyles = makeStyles(theme => ({
  drawerWeb: {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth
    },
    "& .MuiDrawer-paperAnchorLeft":
    {
      marginLeft:-120,
      right:'auto',
      left:"auto"
    },
    "& .MuiList-root": {
      overflow: "auto",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
      "& .MuiPaper-root.MuiDrawer-paper": {
        background: "#1E293B",
      }
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
  },
  drawerMobile: {
    justifyContent: "center",
    width: drawerWidth,
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth
    },
    "& .MuiDrawer-paperAnchorLeft":
    {
      left:0,
      right:'auto'
  
    },
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      "& .MuiPaper-root.MuiDrawer-paper": {
        background: "#1E293B"
      },
      "& .MuiDrawer-paper .MuiList-root": {
        overflow: "auto",
        scrollbarWidth: "none",
        padding: "120px 0"
      },
    }
  },
  list: {
    "& .icon": {
      width: "32px", height: "32px"
    },
    "& .activeItem": {
      backgroundColor: "#181825",
      "& .icon": {
        color: "#0099FF"
      },
      "& .MuiTypography-root": {
        color: "#0099FF"
      }
    }
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    padding: "10px 0px",
    "&:hover": {
      backgroundColor: "#181825",
      "& $icon": {
        color: "#0099FF"
      },
      "& .MuiTypography-root": {
        color: "#0099FF"
      }
    }
  },
  icon: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2px"
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
}));

export type TMenuItem =
  | "dashboard"
  | "manageDept"
  | "manager"
  | "agent"
  | "holiday"
  | "disposition"
  | "transferList"
  | "dialOut"
  | "location"
  | "setting"
  | "click2Call"
  | "addressBook"
  | "campaign"
  | "ivrFlow";
interface IPropSidebar {
  menuItem: TMenuItem;
  userRole: string;
}

export default function Sidebar({ menuItem, userRole }: IPropSidebar) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<string>("");

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const links = [
    {
      id: "dashboard",
      text: "Dashboard",
      icon: <HomeIcon className={"icon"} />,
      link: "/dashboard",
      role: RoleType.All
    },
    {
      id: "manageDept",
      text: "Manage Dept",
      icon: <BarIcon className={"icon"} />,
      link: "/department",
      role: RoleType.Company
    },
    {
      id: "manager",
      text: "Managers",
      icon: <PermIdentity className={"icon"} />,
      link: "/manager",
      role: RoleType.Company
    },
    {
      id: "agent",
      text: "Agents",
      icon: <PermIdentity className={"icon"} />,
      link: "/agents",
      role: RoleType.Company
    },
    {
      id: "holiday",
      text: "Holidays",
      icon: <HolidayIcon className={"icon"} />,
      link: "/holiday",
      role: RoleType.Company
    },
    {
      id: "disposition",
      text: "Disposition",
      icon: <DispositionIcon className={"icon"} />,
      link: "/",
      role: RoleType.Company
    },
    {
      id: "transferList",
      text: "Transfer List",
      icon: <TransferListIcon className={"icon"} />,
      link: "/",
      role: RoleType.Company
    },
    {
      id: "dialOut",
      text: "Dial-out Cont",
      icon: <DialIcon className={"icon"} />,
      link: "/",
      role: RoleType.Company
    },
    {
      id: "location",
      text: "Locations",
      icon: <LocationIcon className={"icon"} />,
      link: "/",
      role: RoleType.Company
    },
    {
      id: "click2Call",
      text: "Click 2 Call",
      icon: images.click2Call,
      selectedIcon: images.click2CallSelected,
      link: "/click2call",
      role: RoleType.Agent
    },
    {
      id: "addressBook",
      text: "Address Book",
      icon: images.addressBook,
      selectedIcon: images.addressBookSelected,
      link: "/addressBook",
      role: RoleType.Agent
    },
    {
      id: "callCalendar",
      text: "Call Calendar",
      icon: images.callCalendar,
      selectedIcon: images.callCalendarSelected,
      link: "/",
      role: RoleType.Agent
    },
    {
      id: "notification",
      text: "Notification",
      icon: images.notification,
      selectedIcon: images.notificationSelected,
      link: "/",
      role: RoleType.Agent
    },
    {
      id: "accountProfile",
      text: "Account profile",
      icon: images.accountProfile,
      selectedIcon: images.accountProfileSelected,
      link: "/",
      role: RoleType.Agent
    },
    {
      id: "campaign",
      text: "Campaign",
      icon: images.campaign,
      selectedIcon: images.campaignSelected,
      link: "/campaign",
      role: RoleType.Company
    },
    {
      id: "ivrFlow",
      text: "Ivr Flows",
      icon: images.ivrFlow,
      selectedIcon: images.ivrFlowSelected,
      link: "/ivrFlow",
      role: RoleType.Company
    },
    {
      id: "setting",
      text: "Settings",
      icon: <SettingIcon className={"icon"} />,
      link: "/setting",
      role: RoleType.All
    }
  ];

  const drawer = (
    <List className={classes.list}>
      {links.filter(item => item.role.includes(userRole)).map(item => (
        <ListItem
          data-test-id="listItem"
          key={item.id}
          component={Link}
          to={item.link}
          className={`${classes.listItem} ${
            menuItem === item.id ? "activeItem" : ""
          }`}
          onMouseEnter={() => setSelectedImageIndex(item.id)}
          onMouseLeave={() => setSelectedImageIndex("")}
        >
          <ListItemIcon className={classes.icon}>
            {!!item.selectedIcon
              ? <img 
                  src={[menuItem, selectedImageIndex].includes(item.id) ? item.selectedIcon : item.icon} 
                /> 
              : item.icon}
          </ListItemIcon>
          <Typography
            style={{ fontSize: "14px", fontFamily: "Poppins", fontWeight: 700, lineHeight: "22px" }}
          >
            {item.text}
          </Typography>
        </ListItem>
      ))}
    </List>
  );

  return (
    <SidebarContainer>
      <AppBarCustom position="static" color="transparent">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            data-testid="btnOpenDrawer"
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBarCustom>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        data-testid="drawerMobile"
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        className={classes.drawerMobile}
      >
        {drawer}
      </Drawer>
      <Drawer id="drawer" className={classes.drawerWeb} variant="permanent" open>
        {drawer}
      </Drawer>
    </SidebarContainer>
  );
}

export const AppBarCustom = styled(AppBar)(({ theme }) => ({
  display: "none",
  width: "48px",
  height: "48px",
  justifyContent: "center",
  boxShadow: "none",
  "& .MuiToolbar-root": {
    backgroundColor: "transparent",
    padding: 0
  },
  "& .MuiToolbar-regular": {
    minHeight: "unset"
  },
  [theme.breakpoints.down("xs")]: {
    display: "block"
  }
}));

export const SidebarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
 
  [theme.breakpoints.down(601)]: {
    width: "40%"
  },
  [theme.breakpoints.down(410)]: {
    width: "30%"
  },
  "& #drawer":{
    "& .MuiList-root": {
      overflow: "auto",
      scrollbarWidth: "none",
      padding: "120px 0"
    },
  },

}));

