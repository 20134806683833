import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

// Customizable Area Start
import * as Yup from 'yup'
import { FormikErrors, FormikHelpers } from "formik";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { getStorageData } from "framework/src/Utilities";
import { Agent } from "./AgentsController";
import { expireTokenHandling } from "../../../components/src/Utilities";

const mobilePhoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const alphanumericRegex = /^[a-zA-Z0-9\s]+$/
const numericSpecialCharRegex = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/
export const validationSchema = Yup.object({
  agentName: Yup.string()
    .matches(alphanumericRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidCharacterError,
    })
    .required(configJSON.requiredField),
  mobile: Yup.string()
    .matches(mobilePhoneRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidMobileNumber,
    })
    .required(configJSON.requiredField),
  alternateNumber: Yup.string()
    .matches(mobilePhoneRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidMobileNumber,
    }),
  extension: Yup.string()
    .matches(numericSpecialCharRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidCharacterError,
    })
    .required(configJSON.requiredField),
  emailId: Yup.string().email("Invalid email-id!").required(configJSON.requiredField),
  callPriority: Yup.string()
    .matches(numericSpecialCharRegex, {
      excludeEmptyString: true,
      message: configJSON.invalidCharacterError,
    })
    .required(configJSON.requiredField),
  username: Yup.string()
    .test("is-username", configJSON.usernameWhitespaceError, val => !/\s/.test(val))
    .required(configJSON.requiredField),
  password: Yup.string().min(6, configJSON.passwordGenericError)
    .test("is-password", configJSON.passwordGenericError, val => /\d/.test(val) && /[a-z]/.test(val) && /[A-Z]/.test(val) && /[^a-zA-Z0-9\s]/.test(val))
    .required(configJSON.requiredField),
  inboundCalls: Yup.boolean(),
  outboundCalls: Yup.boolean(),
  click2Call: Yup.boolean(),
  progressiveDialer: Yup.boolean(),
  blended: Yup.boolean().when([
      'inboundCalls',
      'outboundCalls',
      'click2Call',
      'progressiveDialer',
    ], {
    is: (inboundCalls, outboundCalls, click2Call, progressiveDialer) => !(
      inboundCalls || outboundCalls || click2Call || progressiveDialer
    ),
    then: Yup.boolean().oneOf([true], "At least one needs to be toggled"),
  }),
})
export type FormData = {
  agentName: string;
  mobile: string;
  alternateNumber: string;
  extension: string;
  emailId: string;
  callPriority: string;
  username: string;
  password: string;
  inboundCalls: boolean;
  outboundCalls: boolean;
  click2Call: boolean;
  progressiveDialer: boolean;
  blended: boolean;
}
export type ErrorData = {
  errors: string[]
}
export type SuccessData = {
  message: string
}
// Customizable Area End

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    editAgent?: Agent
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    formData: FormData
    initialErrors: FormikErrors<FormData>;
    resetPassword: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class AddAgentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    createAgentApiId = "";
    updateAgentApiId = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];

      this.state = {
        formData: {
          agentName: "",
          mobile: "",
          alternateNumber: "",
          extension: "",
          emailId: "",
          callPriority: "",
          username: "",
          password: "",
          inboundCalls: true,
          outboundCalls: true,
          click2Call: true,
          progressiveDialer: true,
          blended: true,
        },
        initialErrors: {},
        resetPassword: false,
      };
      // Customizable Area End

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount = async () => {
      // Customizable Area Start
      if (this.props.editAgent) {
        const ea = this.props.editAgent
        this.setState(prev => ({
          ...prev,
          formData: {
            agentName: ea.attributes.agent_name,
            mobile: ea.attributes.full_phone_number.toString(),
            alternateNumber: ea.attributes.alt_phone_number?.toString() ?? "",
            extension: ea.attributes.extension,
            emailId: ea.attributes.email,
            callPriority: ea.attributes.call_priority,
            username: ea.attributes.username,
            password: configJSON.testPass,
            inboundCalls: ea.attributes.agent_mode.some(val => val === "inbound_calls"),
            outboundCalls: ea.attributes.agent_mode.some(val => val === "outbound_calls"),
            click2Call: ea.attributes.agent_mode.some(val => val === "click_2_call"),
            progressiveDialer: ea.attributes.agent_mode.some(val => val === "progressive_dialer"),
            blended: ea.attributes.agent_mode.some(val => val === "blended"),
          }
        }))
      }
      // Customizable Area End
    }

    receive = (from: string, message: Message) => {
      // Customizable Area Start
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const messageId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const successData = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        )
        expireTokenHandling(successData)
        if (messageId === this.createAgentApiId) {
          this.handleCreateUpdateAgent(successData)
        } else if (messageId === this.updateAgentApiId) {
          this.handleCreateUpdateAgent(successData, true)
        }
      }
      // Customizable Area End
    }

    // Customizable Area Start
    handleModeChange(
      mode: "blended" | "inboundCalls" | "outboundCalls" | "click2Call" | "progressiveDialer",
      setValues: (values: React.SetStateAction<FormData>, shouldValidate?: boolean | undefined) => void,
    ) {
      return (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setValues(prev => {
          const data = {...prev}
          data[mode] = checked
          if (mode === "blended") {
            data.inboundCalls = checked;
            data.outboundCalls = checked;
            data.click2Call = checked;
            data.progressiveDialer = checked;
          } else if (!checked) {
            data.blended = false
          } else if (
            data.inboundCalls
            && data.outboundCalls
            && data.click2Call
            && data.progressiveDialer
          ) {
            data.blended = true
          }
          return data
        })
      }
    }

    handleSubmit = async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
      const agentMode = []
      if (values.inboundCalls) {
        agentMode.push("inbound_calls")
      }
      if (values.outboundCalls) {
        agentMode.push("outbound_calls")
      }
      if (values.click2Call) {
        agentMode.push("click_2_call")
      }
      if (values.progressiveDialer) {
        agentMode.push("progressive_dialer")
      }
      if (values.blended) {
        agentMode.push("blended")
      }
      let agent = {
        "agent_name": values.agentName,
        "full_phone_number": values.mobile,
        "alt_phone_number": values.alternateNumber,
        "extension": values.extension,
        "email": values.emailId,
        "call_priority": values.callPriority,
        "username": values.username,
        "password": values.password,
        "password_confirmation": values.password,
        "agent_mode": agentMode,
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      )
      const editAgent = this.props.editAgent
      if (!editAgent) {
        this.createAgentApiId = requestMessage.messageId
        createRequestMessage({
          requestMessage,
          endPoint: configJSON.createAgentEndpoint,
          method: configJSON.postAPIMethod,
          token: await getStorageData("authToken"),
          body: JSON.stringify({agent}),
        })
      } else {
        let updateAgent: object = agent
        if (!this.state.resetPassword) {
          let {password, password_confirmation, ...rest} = agent
          updateAgent = rest
        }
        this.updateAgentApiId = requestMessage.messageId
        createRequestMessage({
          requestMessage,
          endPoint: `${configJSON.createAgentEndpoint}/${editAgent.id}`,
          method: configJSON.putAPIMethod,
          token: await getStorageData("authToken"),
          body: JSON.stringify({agent: updateAgent}),
        })
      }
    }

    handleCreateUpdateAgent = (sData: ErrorData | SuccessData, isUpdate: boolean = false) => {
      if ("errors" in sData) {
        let initialErrors: FormikErrors<FormData> = {}
        for (const val of sData.errors) {
          if (val.search("Username") >= 0) {
            initialErrors.username = configJSON.usernameExistError
          } else if (val.search("Email") >= 0) {
            initialErrors.emailId = configJSON.emailExistError
          } else if (val.search("phone number") >= 0) {
            initialErrors.mobile = configJSON.mobileExistError
          }
        }
        this.setState(prev => ({ ...prev, initialErrors: initialErrors }))
        return
      }
     
        this.props.navigation.goBack()
      
    }

    areAllTrue(...values: boolean[]) {
      return values.every(val => val)
    }

    some(...values: boolean[]) {
      return values.some(val => val)
    }
    
    handleClearInitialError(
      fieldName: keyof FormData,
    ) {
      this.setState(prev => {
        prev.initialErrors[fieldName] = undefined;
        return prev
      })
    }

    handleClearInitialErrorOnChange(
      handleChange: {
          (e: React.ChangeEvent<any>): void;
          <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
      },
      includeValidatedOnlyWhileTyping: boolean = true
    ) {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let name = event.target.name as (keyof FormData)
        let value = event.target.value
        this.handleClearInitialError(name)
        try {
          if (includeValidatedOnlyWhileTyping && value !== "") {
            try {
              validationSchema.fields[name].validateSync(value)
            } catch (error) {
              if (
                (name !== "mobile" && name !== "alternateNumber")
                || (
                  (value.match(/\d/g)?.length ?? 0) >= 10
                  || !numericSpecialCharRegex.test(value)
                 )
              ) {
                throw error
              }
            }
          }
          handleChange(event)
        } catch (_) {
          // noop
        }
      }
    }

    handleClearInitialErrorOnBlur(
      handleBlur: {
          (e: React.FocusEvent<any>): void;
          <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
      },
    ) {
      return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement>) => {
        this.handleClearInitialError(event.target.name as (keyof FormData))
        handleBlur(event)
      }
    }

    handleEditResetPassword(values: FormData) {
      return () => {
        this.setState(prev => ({ ...prev, formData: { ...values, password: "" }, resetPassword: true }))
      }
    }
    // Customizable Area End
}
