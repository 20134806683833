// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { expireTokenHandling } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import { InputBox } from "../../groups2/src/Agents.web";
import { Box, Button, InputAdornment } from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";

export const configJSON = require("./config");
export const images = require("./assets");

type ErrorData = {
  errors: string[];
};
type SuccessData = {
  message: string;
};
type DIDNumberList = {
  id: number;
  number: string;
};
type GetDIDNumberResponse = {
  did_numbers: DIDNumberList[];
};
type ContactList = {
  id: string;
  attributes: {
    name: string;
    contact_number: string;
  };
};
type GetContactListResponse = {
  contacts: {
    data: ContactList[];
  };
};

type CreateCallResponse = {
  status: "success" | "error";
  message: string;
};

type ResponseData =
  | ErrorData
  | SuccessData
  | GetDIDNumberResponse
  | GetContactListResponse
  | CreateCallResponse;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  didNumberList: DIDNumberList[];
  fromNumber: string;
  toNumber: string;
  showContactSelectDialog: boolean;
  contactList: ContactList[];
  selectedContact: string;
  contactSearch: string;
  successMessage: string;
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class Click2CallController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      didNumberList: [],
      fromNumber: "",
      toNumber: "",
      showContactSelectDialog: false,
      contactList: [],
      selectedContact: "",
      contactSearch: "",
      successMessage: "",
      errorMessage: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  getDIDNumberApiCallId = "";
  getContactListApiCallId = "";
  createCallApiCallId = "";

  async componentDidMount() {
    this.getDIDNumbersAPICall();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  handleAPICall = async (apiEndPoint: string, method: string) => {
    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    switch (apiRequestCallId) {
      case this.getDIDNumberApiCallId:
        this.receiveGetDIDNumbersAPICall(responseJson);
        break;
      case this.getContactListApiCallId:
        this.receiveGetContactListAPICall(responseJson);
        break;
      case this.createCallApiCallId:
        this.receiveCreateCallAPICall(responseJson);
        break;
      default:
        break;
    }
  };

  getDIDNumbersAPICall = async () => {
    this.getDIDNumberApiCallId = await this.handleAPICall(
      configJSON.getDIDNumberApiEndPoint,
      configJSON.validationApiMethodType
    );
  };

  receiveGetDIDNumbersAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetDIDNumberResponse;
    if (responseJsonData && responseJsonData.did_numbers) {
      this.setState({
        didNumberList: responseJsonData.did_numbers,
        fromNumber: responseJsonData.did_numbers[0].number,
      });
    }
  };

  getContactListAPICall = async () => {
    this.getContactListApiCallId = await this.handleAPICall(
      `${configJSON.getContactListApiEndPoint}?query=${this.state.contactSearch}`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetContactListAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetContactListResponse;
    if (responseJsonData && responseJsonData.contacts) {
      this.setState({
        contactList: responseJsonData.contacts.data,
        selectedContact: "",
      });
    }
  };

  contactDialogContent = () => (
    <>
      <InputBox
        placeholder="Search"
        type="text"
        data-test-id="contactSearchText"
        startAdornment={
          <InputAdornment
            position="start"
            style={{ zIndex: 1, marginRight: 0 }}
          >
            <SearchRoundedIcon className="search" />
          </InputAdornment>
        }
        value={this.state.contactSearch}
        onChange={this.handleContactSearch}
      />
      <Box className="buttonListing">
        {this.state.contactList.map((contact) => (
          <Button
            data-test-id="contactListItem"
            key={contact.attributes.contact_number}
            variant={
              this.state.selectedContact === contact.attributes.contact_number
                ? "contained"
                : "outlined"
            }
            onClick={() =>
              this.handleSelectContactClick(contact.attributes.contact_number)
            }
          >
            {contact.attributes.name}
          </Button>
        ))}
      </Box>
    </>
  );

  handleFromValue = (
    event: React.ChangeEvent<{ name?: string; value: unknown | string }>
  ) => {
    this.setState({
      fromNumber: event.target.value as string,
      successMessage: "",
      errorMessage: "",
    });
  };

  handleToValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    const regex = new RegExp(/[A-z]/g);
    const newValue = value.replace(regex, "");
    this.setState({
      toNumber: newValue,
      successMessage: "",
      errorMessage: "",
    });
  };

  onClickToOpenContactDialog = () => {
    this.setState({
      contactList: [],
    });
    this.getContactListAPICall();
    this.handleContactSelectDialog();
  };

  handleContactSelectDialog = () => {
    this.setState(
      {
        showContactSelectDialog: !this.state.showContactSelectDialog,
      },
      () => {
        this.setState({
          contactSearch: "",
        });
        if (this.state.showContactSelectDialog) {
          this.setState({
            selectedContact: "",
          });
        }
      }
    );
  };

  handleSelectContactClick = (contactId: string) => {
    this.setState({
      selectedContact: contactId,
    });
  };

  handleContactSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState(
      {
        contactSearch: event.target.value,
      },
      () => {
        if (!this.state.contactSearch || this.state.contactSearch.length > 2) {
          this.getContactListAPICall();
        }
      }
    );
  };

  handleContactDialogDone = () => {
    this.setState({
      toNumber: this.state.selectedContact,
      successMessage: "",
      errorMessage: "",
    });
    this.handleContactSelectDialog();
  };

  handleOnCallClick = async () => {
    this.createCallApiCallId = await this.handleAPICall(
      `${configJSON.createCallApiEndPoint}?company_did_no=${this.state.fromNumber}&end_user_phone_no=${this.state.toNumber}`,
      configJSON.exampleAPiMethod
    );
  };

  receiveCreateCallAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as CreateCallResponse;
    if (responseJsonData && responseJsonData.status === "success") {
      this.setState({
        successMessage: responseJsonData.message,
      });
    }
    if (responseJsonData && responseJsonData.status === "error") {
      this.setState({
        errorMessage: responseJsonData.message,
      });
    }
  };
  // Customizable Area End
}
