// Customizable Area Start
import React, { memo } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { expireTokenHandling } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  ActionButton,
  CustomTooltip,
  StyledCheckbox,
} from "../../groups2/src/Agents.web";
import { MoreVert } from "@material-ui/icons";
import { InBound } from "./AddInBoundIvrController";
export const configJSON = require("./config");
export const images = require("./assets");

export type TTabIndex = "inboundIVR" | "outboundIVR";

type InBoundList = {
  campaigns: {
    data: InBound[];
  };
  search: boolean;
  total_count: number;
  current_page: number;
};

type SuccessData = {
  message: string;
};

type ResponseData = InBoundList | SuccessData;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  tabIndexValue: TTabIndex;
  inBoundListData: InBoundList;
  perPage: number;
  search: string;
  openId: string;
  selectedMode: boolean;
  selectAll: boolean;
  selectedIds: number[];
  bulkDeleteConfirmation: boolean;
  deleteConfirmationId: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CampaignController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
      tabIndexValue: "inboundIVR",
      inBoundListData: {
        campaigns: { data: [] },
        search: false,
        total_count: 0,
        current_page: 1,
      },
      perPage: 10,
      search: "",
      openId: "",
      selectedMode: false,
      selectAll: false,
      selectedIds: [],
      bulkDeleteConfirmation: false,
      deleteConfirmationId: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  getInBoundListApiCallId: string = "";
  deleteCampaignApiCallId: string = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  async componentDidMount() {
    this.handleConditionalAPICall();
  }

  handleAPICall = async (apiEndPoint: string, method: string) => {
    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    if (apiRequestCallId === this.getInBoundListApiCallId) {
      this.receiveGetInBoundListAPICall(responseJson);
    }
    if (apiRequestCallId === this.deleteCampaignApiCallId) {
      this.receiveDeleteCampaignAPICall(responseJson);
    }
  };

  goToNavigation = (route: string, editId?: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    if (editId) {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData("EditCampaign", editId);
      navigationMsg.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
    }

    this.send(navigationMsg);
  };

  handleConditionalAPICall = (page = 1) => {
    if (this.state.tabIndexValue === "inboundIVR") {
      this.getInBoundListAPICall(page);
    }
  };

  handleChangeTab = (tabIndex: TTabIndex) => {
    if (tabIndex !== this.state.tabIndexValue)
      this.setState(
        {
          tabIndexValue: tabIndex,
          search: "",
        },
        () => this.handleConditionalAPICall()
      );
  };

  handleToggleAction = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { name } = event.currentTarget;
    if (!this.state.selectedMode) {
      this.setState((prevState) => ({
        openId: prevState.openId === name ? "" : name,
      }));
    }
  };

  handleCloseAction = () => {
    this.setState((prev) => ({ ...prev, openId: "" }));
  };

  handleSearch = async (value: string) => {
    this.setState({ search: value }, () => {
      if (value.length == 0 || value.length > 2) {
        this.handleConditionalAPICall();
      }
    });
  };

  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    this.handleConditionalAPICall(page + 1);
  };

  handleRowPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        perPage: +event.target.value,
      },
      () => this.handleConditionalAPICall()
    );
  };

  handleSelectOption = () => {
    this.setState(
      {
        selectedMode: !this.state.selectedMode,
        selectAll: false,
      },
      () => this.handleSelectAllCampaignDelete(false)
    );
  };

  handleSelectAll = (checked: boolean) => {
    this.setState({ selectAll: !checked }, () =>
      this.handleSelectAllCampaignDelete(this.state.selectAll)
    );
  };

  handleAllDelete = () => {
    this.handleDeleteCampaign(this.state.selectedIds);
  };

  handleSelectAllCampaignDelete = (selectAll: boolean) => {
    const newCampaignData = this.state.inBoundListData.campaigns.data.map(
      (campaign) => {
        campaign.isSelected = selectAll;
        return campaign;
      }
    );
    const updatedData = {
      ...this.state.inBoundListData,
      campaigns: { data: newCampaignData },
    };
    this.setState({ inBoundListData: updatedData });
    this.handleSelectContactId();
  };

  handleSelectContactId = () => {
    const filteredData = this.state.inBoundListData.campaigns.data.filter(
      (campaign) => campaign.isSelected
    );
    const campaignIds = filteredData.map((campaign) => campaign.attributes.id);
    this.setState({ selectedIds: campaignIds }, () =>
      this.checkAllSelectedCampaign()
    );
  };

  checkAllSelectedCampaign = () => {
    if (
      this.state.selectedIds.length ==
      this.state.inBoundListData.campaigns.data.length
    ) {
      this.setState({ selectAll: true });
    } else {
      this.setState({ selectAll: false });
    }
  };

  updateSelectedContactData = (campaignId: number) => {
    const newCampaignData = this.state.inBoundListData.campaigns.data.map(
      (campaign) => {
        if (campaign.attributes.id === campaignId) {
          campaign.isSelected = !campaign.isSelected;
        }
        return campaign;
      }
    );
    const updatedData = {
      ...this.state.inBoundListData,
      campaigns: { data: newCampaignData },
    };
    this.setState({ inBoundListData: updatedData });
    this.handleSelectContactId();
  };

  getInBoundListAPICall = async (page: number) => {
    this.setState({
      isLoading: true,
      openId: "",
      selectedMode: false,
      selectAll: false,
      selectedIds: [],
    });
    this.getInBoundListApiCallId = await this.handleAPICall(
      `${configJSON.campaignApiEndPoint}?page=${page}&per_page=${
        this.state.perPage
      }&query=${this.state.search}`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetInBoundListAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as InBoundList;
    if (responseJsonData && responseJsonData.campaigns) {
      this.setState({
        inBoundListData: {
          ...responseJsonData,
          campaigns: {
            data: responseJsonData.campaigns.data.map((campaign) => ({
              ...campaign,
              isSelected: false,
            })),
          },
        },
        isLoading: false,
      });
    }
  };

  handleBulkDeleteConfirmationDialog = () => {
    this.setState({
      bulkDeleteConfirmation: !this.state.bulkDeleteConfirmation,
    });
  };

  handleDeleteConfirmationDialog = (campaignId: number) => {
    this.setState({ deleteConfirmationId: campaignId });
  };

  handleDeleteCampaign = async (deleteId: number | number[]) => {
    this.setState({
      isLoading: true,
      deleteConfirmationId: 0,
      bulkDeleteConfirmation: false,
    });

    this.deleteCampaignApiCallId = await this.handleAPICall(
      `${configJSON.campaignApiEndPoint}?campaign_ids=[${deleteId}]`,
      configJSON.deleteAPiMethod
    );
  };

  receiveDeleteCampaignAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as SuccessData;
    if (responseJsonData && responseJsonData.message) {
      this.handleConditionalAPICall();
    }
  };

  renderNoData() {
    return this.state.isLoading ? (
      <></>
    ) : (
      <Box className="campaignDataContainer" data-test-id="no-data">
        <Box className="campaignNoDataInnerContainer">
          <img src={images.clipboards} className="noDataImage" />
          <Typography className="campaignNoDataMainText">
            {configJSON.campaignNoDataMainText}
          </Typography>
          <Typography className="campaignNoDataSubText">
            {configJSON.campaignNoDataSubText}
          </Typography>
        </Box>
      </Box>
    );
  }

  renderNoSearchData() {
    return (
      <Box className="campaignDataContainer" data-test-id="no-search-data">
        <Box className="campaignNoDataInnerContainer">
          <img src={images.noResultFound} className="noDataImage" />
          <Typography className="campaignNoDataMainText">
            {configJSON.noResultFound}
          </Typography>
          <Typography className="campaignNoDataSubText">
            {configJSON.noInBoundMatched}
          </Typography>
        </Box>
      </Box>
    );
  }

  renderInBoundNoData() {
    return this.state.inBoundListData.search
      ? this.renderNoSearchData()
      : this.renderNoData();
  }

  renderInBoundView() {
    return this.state.inBoundListData.campaigns.data.length ? (
      <TableComponent
        data-test-id="tableComponent"
        goToNavigation={this.goToNavigation}
        handleCloseAction={this.handleCloseAction}
        handleDeleteConfirmationDialog={this.handleDeleteConfirmationDialog}
        handlePageChange={this.handlePageChange}
        handleRowsPerPageChange={this.handleRowPerPageChange}
        handleSelectOption={this.handleSelectOption}
        handleToggleAction={this.handleToggleAction}
        inBoundListData={this.state.inBoundListData}
        openId={this.state.openId}
        perPage={this.state.perPage}
        selectedMode={this.state.selectedMode}
        updateSelectedContactData={this.updateSelectedContactData}
        webStyle={this.webStyle}
      />
    ) : (
      this.renderInBoundNoData()
    );
  }

  webStyle = {
    tooltipButton: {
      textTransform: "none",
      justifyContent: "start",
      color: "#0F172A",
      fontSize: "14px",
      fontFamily: "Poppins",
      fontWeight: 400,
      lineHeight: "22px",
    },
    redText: {
      color: "#DC2626",
    },
  } as const;

  // Customizable Area End
}

interface TableComponentProps {
  inBoundListData: InBoundList;
  selectedMode: boolean;
  updateSelectedContactData: (id: number) => void;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  goToNavigation: (path: string, id: string) => void;
  handleDeleteConfirmationDialog: (id: number) => void;
  handleSelectOption: () => void;
  handleToggleAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseAction: () => void;
  webStyle: { [key: string]: React.CSSProperties };
  openId: string | null;
  perPage: number;
}

const TableComponent: React.FC<TableComponentProps> = memo((props) => {
  const {
    inBoundListData,
    selectedMode,
    updateSelectedContactData,
    handlePageChange,
    handleRowsPerPageChange,
    goToNavigation,
    handleDeleteConfirmationDialog,
    handleSelectOption,
    handleToggleAction,
    handleCloseAction,
    webStyle,
    openId,
    perPage,
  } = props;

  return (
    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            {selectedMode ? <TableCell className="tableHeader" /> : <></>}
            <TableCell className="tableHeader">
              {configJSON.campaignName}
            </TableCell>
            <TableCell className="tableHeader">
              {configJSON.campaignDID}
            </TableCell>
            <TableCell className="tableHeader">
              {configJSON.campaignDate}
            </TableCell>
            <TableCell className="tableHeader">
              {configJSON.campaignTime}
            </TableCell>
            <TableCell className="tableHeader">{configJSON.action}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inBoundListData.campaigns.data.map((campaign) => (
            <TableRow key={campaign.id} data-test-id="campaignTableRow">
              {selectedMode ? (
                <TableCell component="th" scope="row">
                  <StyledCheckbox
                    onClick={() =>
                      updateSelectedContactData(campaign.attributes.id)
                    }
                    data-test-id={`checkbox-delete`}
                    checked={campaign.isSelected}
                  />
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell component="th" scope="row" className="tableRow">
                {campaign.attributes.name}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {campaign.attributes.did_number.number}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {campaign.attributes.created_date}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {campaign.attributes.created_time}
              </TableCell>
              <TableCell className="tableRow">
                <ClickAwayListener
                  mouseEvent={openId === campaign.id ? "onClick" : false}
                  onClickAway={handleCloseAction}
                >
                  <CustomTooltip
                    interactive
                    open={campaign.id === openId}
                    placement="left-start"
                    title={
                      <React.Fragment>
                        <Button
                          data-test-id="edit-campaign"
                          style={webStyle.tooltipButton}
                          onClick={() =>
                            goToNavigation("AddInBoundIVR", campaign.id)
                          }
                        >
                          {configJSON.edit}
                        </Button>
                        <Button
                          data-test-id="select-campaign"
                          style={webStyle.tooltipButton}
                          onClick={handleSelectOption}
                        >
                          {configJSON.select}
                        </Button>
                        <Button
                          data-test-id="delete-campaign"
                          style={{
                            ...webStyle.tooltipButton,
                            ...webStyle.redText,
                          }}
                          onClick={() =>
                            handleDeleteConfirmationDialog(
                              campaign.attributes.id
                            )
                          }
                        >
                          {configJSON.delete}
                        </Button>
                      </React.Fragment>
                    }
                  >
                    <ActionButton
                      data-test-id="campaignActionButton"
                      name={campaign.id.toString()}
                      size="small"
                      onClick={handleToggleAction}
                      style={{
                        background:
                          campaign.id === openId ? "#0099FF" : "white",
                      }}
                    >
                      <MoreVert
                        fontSize="medium"
                        style={{
                          zIndex: 2,
                          color: campaign.id === openId ? "white" : "#0F172A",
                        }}
                      />
                    </ActionButton>
                  </CustomTooltip>
                </ClickAwayListener>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className="borderNone"
              colSpan={8}
              count={inBoundListData.total_count}
              rowsPerPage={perPage}
              page={inBoundListData.current_page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});
