import React, { Component } from "react";
import {
    Box,
    createTheme,
    styled,
    ThemeProvider,
} from "@material-ui/core";
import Sidebar, { TMenuItem } from "../../blocks/dashboard/src/Sidebar.web";
import Header from "../../blocks/dashboard/src/Header.web";
import { getStorageData } from "../../framework/src/Utilities";

type Props = {
  menuItem: TMenuItem;
  navigation: {
    navigate: (key: string) => void;
  };
  children: React.ReactNode;
};

type S = {
  userRole: string;
}

const theme = createTheme({
  typography: {
    fontFamily: "Poppins"
  }
})

export default class Layout extends Component<Props, S> {
    constructor(props: Props) {
      super(props);

      this.state = {
        userRole: ""
      }
    }

    async componentDidMount() {
      this.setState({
        userRole: await getStorageData("role")
      })
    }

    render() {
        return (
          <ThemeProvider theme={theme}>
            <BoxContainer>
                <LayoutCommonContainer>
                    <Sidebar menuItem={this.props.menuItem} userRole={this.state.userRole}/>
                    <Header navigation={this.props.navigation} />
                </LayoutCommonContainer>
                <ContentContainer>
                  {this.props.children}
                </ContentContainer>
            </BoxContainer>
          </ThemeProvider>
        );
    }
}

export const LayoutCommonContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2px",
    boxShadow: "0px 4px 8px 0px #00000008",
    "& #header": {
        marginLeft: "0"
    },
    background: "white"
}))

export const BoxContainer = styled(Box)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
      marginLeft: "120px",
  },
  display: "flex",
  flexDirection: "column",
  height: "100%",
}))

export const ContentContainer = styled(Box)(() => ({
  flexGrow: 1,
  padding:"40px",
  background: "#F9F9F9",
  display: "flex",
  flexDirection: "column",
  gap: 18,
  "@media (max-width:959px)": {
    padding:"30px !important",
  },
  "@media (max-width:600px)": {
    padding:"20px !important",
  }
}))
