// Customizable Area Start
import { expireTokenHandling } from "../../../components/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  handleCloseModalAdd: () => void;
  fileUploadSuccessfully: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  fileVerifyError: string;
  isFileVerifyLoading: boolean;
  isFileUploaded: boolean;
  isDoneLoading: boolean;
  isDialogOpen: boolean;
  isDoneError: boolean;
  downloadLink: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddBulkHolidayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      fileVerifyError: "",
      isFileVerifyLoading: false,
      isFileUploaded: false,
      isDoneLoading: false,
      isDialogOpen: false,
      isDoneError: false,
      downloadLink: "",
      // Customizable Area End
    };
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson)

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  handleAPICall = async (apiEndPoint: string, file: File) => {
    const data = new FormData();
    data.append("file", file);

    const headers = {
      Token: await getStorageData("authToken"),
    };

    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postDataMethod
    );

    createApiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), data);

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleReceiveFunction = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.verifyFileApiCallId) {
      this.receiveVerifyFileAPICall(responseJson);
    } else if (apiRequestCallId === this.bulkUploadApiCallId) {
      this.receiveBulkUploadAPICall(responseJson);
    }
  };

  verifyFileApiCallId: string = "";
  bulkUploadApiCallId: string = "";

  handleDownloadBtn = async (fileType: string) => {
    const userId = await getStorageData("userId");
    window.open(
      `${baseURL}/${configJSON.downloadFileApiEndPoint}${fileType}&account_id=${userId}`
    );
  };

  verifyUploadedFile = async (file: File) => {
    this.setState({ isFileVerifyLoading: true });
    this.verifyFileApiCallId = await this.handleAPICall(
      configJSON.verifyFileApiEndPoint,
      file
    );
  };

  receiveVerifyFileAPICall = (responseJson: any) => {
    this.setState({ isFileVerifyLoading: false });
    if (responseJson.error) {
      this.setState({ fileVerifyError: responseJson.error });
    } else if (responseJson.message) {
      this.setState({ isFileUploaded: true });
    }
  };

  handleDelete = () => {
    this.setState({
      fileVerifyError: "",
      isFileUploaded: false,
    });
  };

  handleDone = async (file: File) => {
    this.setState({
      isDoneLoading: true,
      isDialogOpen: true,
      isDoneError: false,
    });
    this.bulkUploadApiCallId = await this.handleAPICall(
      configJSON.bulkUploadApiEndPoint,
      file
    );
  };

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
    });
  };

  receiveBulkUploadAPICall = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        isDoneLoading: false,
        downloadLink: responseJson.report_download_link,
      });
    } else {
      this.setState({ isDoneLoading: false, isDoneError: true });
    }
  };
  // Customizable Area End
}
