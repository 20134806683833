import React from "react";

import {
    Box,
    Button,
    InputLabel,
    Typography,
    styled,
    InputBase,
    SwitchProps,
    Switch,
    // Customizable Area Start
    FormGroup,
    TextField,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AddManagerController, { Props, FormData, validationSchema } from "./AddManagerController.web";
import { Form, Formik, FormikProps } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
// Customizable Area End

export default class AddManager extends AddManagerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
                // Customizable Area End
    }

    // Customizable Area Start

    static Manager_INPUT_CONSTANT = [
        {
            id: "managerName",
            label: "Manager Name*",
            type: "text"
        },
        {
            id: "mobile",
            label: "Mobile*",
            type: "text"
        },
        {
            id: "extension",
            label: "Extension*",
            type: "text"
        },
        {
            id: "emailId",
            label: "Email Address*",
            type: "email"
        },
  
    ] as const
  
    static PASSWORD_REQUIRED = ["Minimum 6 characters", "1 number", "1 special character", "1 uppercase and lowercase letter"]

    renderPassword = (
      values: FormData,
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
      onBlur: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
      passwordHasError: boolean,
      error: string = ""
    ) => {
      const passwordLabel = <InputLabelCustom error={passwordHasError} htmlFor="password">Password</InputLabelCustom>
      const resetPasswordButton = (<Box style={styles.passwordLabelContainer}>
          {
            this.props.editManager && !this.state.resetPassword ?
              (<Button
                data-test-id="reset-password"
                variant="contained"
                style={styles.resetButton}
                onClick={super.handleEditResetPassword(values)}
              >
                Reset
              </Button>) :
              (<></>)
          }
        </Box>)
        const passFieldWithError = !this.props.editManager || this.state.resetPassword ?
          (
            <>
              <InputBaseCustom
                hidden={!this.state.resetPassword}
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                value={values.password}
                onChange={onChange}
                onBlur={onBlur}
                style={passwordHasError ? styles.errorText : {}}
              />
              {
                passwordHasError
                && (<Typography color="error">{error}</Typography>)
              }
            </>
          ) :
          (<></>)
      let passwordHeader = passwordLabel
      if (this.props.editManager && !this.state.resetPassword) {
        passwordHeader = (
          <Box style={styles.passwordLabelContainer}>
            {passwordLabel}
            {resetPasswordButton}
          </Box>
        )
      }
      return (
        <>
          {passwordHeader}
          {passFieldWithError}
        </>
      )
    }

    renderForm = ({
        values,
        errors,
        touched,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }: FormikProps<FormData>
    ) => {
      let usernameHasError = super.areAllTrue(
        !!touched.username,
        super.some(!!errors.username, !!this.state.initialErrors.username),
      )
      let passwordHasError = super.areAllTrue(
        !!touched.password,
        super.some(!!errors.password, !!this.state.initialErrors.password),
      )
      let isDepartmentSelected =
      !!this.state.selectedDepartment.length;
     
      let isSaveDisable = this.some(
          isSubmitting,
          Object.keys(errors).length !== 0,
          !this.areAllTrue(
            !!touched.managerName,
            !!touched.mobile,
            !!touched.extension,
            !!touched.emailId,
            !!touched.username,
            !!isDepartmentSelected,
            values.password !== "",
          ),
        )
     
      return (
        <Form translate="" onSubmit={handleSubmit}>
          <FormGroup >
            <ManagerInformationContainer>
                {AddManager.Manager_INPUT_CONSTANT.map(input => {
                    console.log("nput.id::",input.id)
                    const hasError = super.areAllTrue(!!touched[input.id], super.some(!!errors[input.id], !!this.state.initialErrors[input.id]))
                    return (<Box key={input.id}>
                        <InputLabelCustom error={hasError} htmlFor={input.id}>{input.label}</InputLabelCustom>
                        <InputBaseCustom
                          error={hasError}
                          id={input.id}
                          name={input.id}
                          type={input.type}
                          value={values[input.id]}
                          onChange={super.handleClearInitialErrorOnChange(handleChange, input.id !== "emailId")}
                          onBlur={super.handleClearInitialErrorOnBlur(handleBlur)}
                          style={hasError ? styles.errorText : {}}
                        />
                        {hasError && (<Typography color="error">{errors[input.id] ?? this.state.initialErrors[input.id]}</Typography>)}
                    </Box>
                )})}
                  <Box className="manager-box">
            <div className="manager-text">
              Add Department *{""}
              <p>(You can Choose more than one Department)</p>
            </div>
        
                <div className="manager-listing">
                  
                  <div
                    className="manager-div"
                    style={{ marginRight: "42px" }}
                  >
                    <div className="manager-text-1">Department</div>
                    <div className="manager-sd-box">
                      <div className="manager-search-box">
                        <div className="search-box">
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            multiple
                            clearOnBlur
                            disableClearable
                            value={this.state.selectedDepartment}
                            options={this.state.departmentData}
                            getOptionLabel={(option: {
                              name: any;
                              id: any;
                            }) => {
                              return option.name;
                            }}
                            onChange={(_, newValue) => {
                             this.handleAgentChange(newValue);
                            }}
                            renderInput={(params) => (
                              <div>
                                <TextField
                                  {...params}
                                  placeholder="Select Department ..."
                                />
                                <SearchRoundedIcon className="search" />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
           
          </Box>
            </ManagerInformationContainer>
          
            <ManagerAccountContainer>

                <Box className="managerAccount_field">
                    <InputLabelCustom error={usernameHasError} htmlFor="username">Username*</InputLabelCustom>
                    <InputBaseCustom
                      disabled={!!this.props.editManager}
                      id="username"
                      name="username"
                      type="text"
                      value={values.username}
                      onChange={super.handleClearInitialErrorOnChange(handleChange)}
                      onBlur={super.handleClearInitialErrorOnBlur(handleBlur)}
                      style={usernameHasError ? styles.errorText : {}}
                    />
                    {
                      usernameHasError
                      && (<Typography color="error">{errors.username ?? this.state.initialErrors.username}</Typography>)
                    }
                </Box>
                <Box className="managerAccount_field">
                  {this.renderPassword(
                    values,
                    super.handleClearInitialErrorOnChange(handleChange, false),
                    super.handleClearInitialErrorOnBlur(handleBlur),
                    passwordHasError,
                    errors.password ?? this.state.initialErrors.password,
                    )}
                </Box>
            </ManagerAccountContainer>
            {
              !this.props.editManager || this.state.resetPassword ?
              (
                 <RequiredPasswordContainer>
                 <Typography component="p">Create a password with following details</Typography>
                 <ul>
                 {AddManager.PASSWORD_REQUIRED.map((require,idx) => (
                       <li key={idx}>
                       {require}
                       </li>
                       ))}
                 </ul>
                 </RequiredPasswordContainer>
              ) :
              (<></>)
            }
           
            <Box>
                <ButtonCustom
                  type="submit"
                  disabled={
                    this.some(
                      !this.props.editManager,
                      this.state.resetPassword && values.password === "",
                    ) && isSaveDisable || this.state.selectedDepartment.length==0
                  }
                >
                    Save
                </ButtonCustom>
            </Box>
          </FormGroup>
        </Form>
      )
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Formik
              initialValues={this.state.formData}
              initialErrors={this.state.initialErrors}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {this.renderForm}
            </Formik>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
  errorText: {
    color: "red"
  },
  passwordLabelContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    columnGap: 250,
    alignItems: "center",
  },
  resetButton: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "none",
    color: "white",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    borderRadius: 8,
    padding: "2px 16px",
    marginBottom: 10,
    marginTop: 10,
  },
} as const

export const ManagerInformationContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    gridRowGap: "20px",
    marginTop: "9px",
    "& .manager-box": {
      background: "#f8fafc",
      borderRadius: "16px",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "18px",
      maxWidth: "485px",
      "& .manager-text": {
        color: "rgba(51, 65, 85, 1)",
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "28px",
        "& p": {
          color: "rgba(100, 116, 139, 1)",
          fontWeight: 400,
          fontFamily: "Poppins",
          fontSize: "16px",
          margin:0,
          marginTop:15

        },
        
      },
      "& .manager-listing": {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
      },
      "& .manager-text-1": {
        color: "#64748B",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "22px",
        marginBottom: "4px",
      },
      "& .priority-box": {
        background: "#FFFFFF",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        width: "60px",
        height: "56px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .manager-div": {
        width: "100%",
        "& manager-textsmall": {
          color: "rgba(100, 116, 139, 1)",
          fontWeight: 400,
          fontFamily: "Poppins",
          fontSize: "14px",
          margin:0,
          marginTop:15

        },
        "& .manager-sd-box": {
          display: "flex",
          alignItems: "center",
          gap: "5px",
          "& .manager-search-box": {
            background: "#ffffff",
            width: "100%",
            padding: "12px 8px",
            border: "1px solid #CBD5E1",
            borderRadius: "8px",
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
            "& .search-box": {
              color: "#94A3B8",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              overflowWrap: "anywhere",
              width: "100%",
              position: "relative",
              "& .MuiInputBase-root": {
                color: "#94A3B8",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                gap: "8px",
                "&:before": {
                  content: "none",
                },
                "&:after": {
                  content: "none",
                },
                "& .MuiAutocomplete-input": {
                  minWidth: "114px",
                },
                "& .MuiAutocomplete-tag": {
                  margin: "0",
                  background: "#F1F5F9",
                },
                "& .MuiChip-label": {
                  color: "#334155",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                },
              },
              "& .MuiIconButton-root": {
                padding: 0,
              },
              "& .search.MuiSvgIcon-root": {
                color: "#94A3B8",
                background: "#ffffff",
                position: "absolute",
                right: 0,
                bottom: "4px",
              },
            },
          },
          "& .MuiButtonBase-root": {
            padding: "0",
            marginRight: "13px",
            "& .MuiSvgIcon-root": {
              color: "#0099FF",
            },
          },
        },
      },
      "& .add-another-levels": {
        width: "fit-content",
        padding: 0,
        textTransform: "none",
        color: "#0099FF",
        fontWeight: 600,
        fontSize: "20px",
        fontFamily: "Poppins",
        lineHeight: "28px",
        "& .MuiButton-label": {
          gap: "18px",
        },
      },
    },
    "& .day-list-button": {
      "&.MuiButton-root": {
        textTransform: "none",
        color: "#64748B",
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "22px",
        padding: "2px 4px",
        minWidth: "48px",
        borderRadius: "20px",
        border: "1px solid transparent",
      },
      "&.MuiButton-outlined": {
        border: "1px solid #99D6FF",
        background: "#E5F5FF",
        color: "#0099FF",
      },
    },
    "& .list-box": {
      maxWidth: "504px",
      marginTop: "4px",
      padding: "12px 8px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      display: "flex",
      gap: "8px",
      flexWrap: "wrap",
      "& .input-box": {
        background: "#F1F5F9",
        borderRadius: "26px",
        padding: "5px 8px 5px 12px",
        display: "flex",
        alignItems: "center",
        color: "#334155",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        overflowWrap: "anywhere",
        "& .number.MuiInputBase-root": {
          maxWidth: "100px",
        },
        "& .MuiInputBase-root": {
          maxWidth: "65px",
          color: "#94A3B8",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
        },
        "& .MuiInputBase-input": {
          padding: "4px 0",
        },
        "& .MuiIconButton-root": {
          padding: 0,
          marginLeft: "4px",
          "&.Mui-disabled": {
            "& .MuiSvgIcon-root": {
              color: "#94A3B8",
            },
          },
        },
        "& .MuiSvgIcon-root": {
          width: "16px",
          height: "16px",
          color: "#334155",
        },
      },
    },
}))



export const ButtonCustom = styled(Button)(() => ({
    maxWidth: "520px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "8px",
    padding: "16px",
    color: "#FFFFFF",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "none",
    marginTop: "108px",
    marginBottom: "93px",
    "&:disabled": {
      backgroundColor: "#E2E8F0",
      borderColor: "#CBD5E1",
      color: "#334155",
    },
    "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#0099FF",
        borderColor: "#0099FF",
    }
}))

export const ManagerAccountContainer = styled(Box)(() => ({
    marginTop: "20px",
    marginBottom: "37px",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px"
}))

export const RequiredPasswordContainer = styled(Box)(() => ({
    "& p":{
        color: "#334155",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px"
    },
    "& ul": {
        marginTop: "10px",
        marginBottom: "0",
        paddingLeft: "25px",
        "& li":{
            color: "#334155",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px"
        }
    }
}))



export const InputLabelCustom = styled(InputLabel)(() => ({
    color: '#334155',
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "26px",
}))

export const InputBaseCustom = styled(InputBase)(({ theme }) => ({
    maxWidth: "520px",
    width: "100%",
    'label + &': {
        marginTop: "4px",
    },
    '& .MuiInputBase-input': {
        maxWidth: "520px",
        height: "34px",
        borderRadius: 8,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: '1px solid',
        borderColor: '#CBD5E1',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
    },
}));


// Customizable Area End
