// Customizable Area Start
import React, { memo } from "react";
import {
  Typography,
  Box,
  Button,
  styled,
  InputAdornment,
  Container,
  TextField,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ClickAwayListener,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddressBookController, {
  Props,
  images,
  configJSON,
  TTabIndex,
  GetContactData,
} from "./AddressBookController";
import Layout from "../../../components/src/Layout.web";
import {
  ActionButton,
  CustomTooltip,
  InputBox,
  StyledCheckbox,
} from "../../groups2/src/Agents.web";
import CustomDialog from "../../../components/src/CustomDialog.web";
import Loader from "../../../components/src/Loader.web";
import { MoreVert } from "@material-ui/icons";
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";

const TAB_ACTION: {
  id: TTabIndex;
  label: string;
}[] = [
  {
    id: "contacts",
    label: "Contacts",
  },
  {
    id: "contactGroup",
    label: "Contact Group",
  },
];
// Customizable Area End

export default class AddressBook extends AddressBookController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderContactNoData() {
    return this.state.contactList.search
      ? this.renderNoSearchData()
      : this.renderNoData();
  }

  renderContactView() {
    return this.state.contactList.contacts.data.length ? (
      <TableComponent
        data-test-id="tableComponent"
        contactList={this.state.contactList}
        goToNavigation={this.goToNavigation}
        handleCloseAction={this.handleCloseAction}
        handleDeleteConfirmationContactDialog={
          this.handleDeleteConfirmationContactDialog
        }
        handleIfCondition={this.handleIfCondition}
        handlePageChange={this.handlePageChange}
        handleRowsPerPageChange={this.handleRowPerPageChange}
        handleSelectOption={this.handleSelectOption}
        handleToggleAction={this.handleToggleAction}
        openId={this.state.openId}
        perPage={this.state.perPage}
        selectedMode={this.state.selectedMode}
        updateSelectedContactData={this.updateSelectedContactData}
        webStyle={this.webStyle}
      />
    ) : (
      this.renderContactNoData()
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const DialogContent = (
      <>
        <Typography style={webStyle.groupNameLabel}>
          {configJSON.groupName}
        </Typography>
        <TextField
          data-test-id="group-name"
          className="textField"
          fullWidth
          value={this.state.contact_group.name}
          onChange={this.handleGroupName}
        />
        {!!this.state.contactGroupNameError && (
          <Typography color="error">
            {this.state.contactGroupNameError}
          </Typography>
        )}
      </>
    );

    return (
      <Layout menuItem="addressBook" navigation={this.props.navigation}>
        <Loader loading={this.state.isLoading} />
        <MainContainer data-test-id="addressBookComponent">
          <Box
            className="addressBookHeader"
            style={{ background: this.state.selectedMode ? "white" : "" }}
          >
            <Box className="headerBox">
              <img src={images.group} width={64} height={48} />
              <Typography className="addressBookHeaderTitle">
                {configJSON.addressBook}
              </Typography>
              <InputBox
                placeholder="Search"
                type="text"
                data-test-id="searchText"
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ zIndex: 1, marginRight: 0 }}
                  >
                    <SearchRoundedIcon className="search" />
                  </InputAdornment>
                }
                value={this.state.search}
                onChange={(event: { target: { value: string } }) =>
                  this.handleSearch(event.target.value)
                }
              />
              {this.state.selectedMode ? (
                <Box style={{ display: "flex" }}>
                  <Box
                    style={{
                      display: "flex",
                      fontFamily: "Poppins",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                    <StyledCheckbox
                      data-test-id={"select_all"}
                      checked={this.state.selectAll}
                      onChange={(event, checked) =>
                        this.handleSelectAll(!checked)
                      }
                    />
                    <Box
                      data-test-id={"select_all_text"}
                      onClick={() => this.handleSelectAll(this.state.selectAll)}
                      style={{
                        fontWeight: 700,
                        color: "rgba(100, 116, 139, 1)",
                        fontSize: "16px",
                        cursor: "pointer",
                        paddingLeft: "7px",
                      }}
                    >
                      {!this.state.selectAll
                        ? configJSON.selectAll
                        : configJSON.unselectAll}
                    </Box>
                  </Box>
                  {this.state.selectedIds.length > 0 ? (
                    <Box
                      data-test-id="select_delete"
                      onClick={this.handleBulkDeleteConfirmationDialog}
                      style={{
                        display: "flex",
                        marginLeft: "20px",
                        fontFamily: "Poppins",
                        cursor: "pointer",
                        alignItems: "center",
                      }}
                    >
                      <DeleteOutlineRoundedIcon
                        style={{
                          color: "rgba(220, 38, 38, 1)",
                          marginRight: "5px",
                        }}
                      />
                      <Box
                        style={{
                          fontWeight: 700,
                          color: "rgba(220, 38, 38, 1)",
                          fontSize: "16px",
                        }}
                      >
                        {configJSON.delete}
                      </Box>
                    </Box>
                  ) : null}
                  <Box
                    data-test-id="cancel_all"
                    style={{
                      display: "flex",
                      fontFamily: "Poppins",
                      cursor: "pointer",
                      fontWeight: 700,
                      marginLeft: "20px",
                      color: "rgba(217, 119, 6, 1)",
                      fontSize: "16px",
                    }}
                    onClick={() => this.handleSelectOption()}
                  >
                    {configJSON.cancel}
                  </Box>
                </Box>
              ) : null}
            </Box>
            <Box display="flex" gridGap={30}>
              <Button
                data-test-id="add-group"
                variant="contained"
                className="addressBookHeaderButton"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={this.handleDialogChange}
                disabled={this.state.selectedMode}
              >
                {configJSON.addGroup}
              </Button>
              <Button
                data-test-id="add-contact"
                variant="contained"
                className="addressBookHeaderButton"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disabled={this.state.selectedMode}
                onClick={() => this.goToNavigation("AddressBookAction")}
              >
                {configJSON.addContact}
              </Button>
            </Box>
          </Box>
          <Box className="addressBookActionTabContainer">
            <Container maxWidth="xl" className="tabContainer">
              <Box className="tabBox">
                {TAB_ACTION.map((tabAction) => (
                  <Box
                    key={tabAction.id}
                    className={`tabItem ${
                      tabAction.id !== this.state.tabIndexValue
                        ? "tabItem-disable"
                        : ""
                    }`}
                    onClick={() => this.handleChangeTab(tabAction.id)}
                    data-test-id="tabItem"
                  >
                    <Typography component="h3" className="labelAction">
                      {tabAction.label}
                    </Typography>
                    <Box className="underLine" />
                  </Box>
                ))}
              </Box>
            </Container>
            <Container className="actionContainer">
              {this.state.tabIndexValue === "contacts"
                ? this.renderContactView()
                : this.renderGroupView()}
            </Container>
          </Box>
        </MainContainer>
        <CustomDialog
          open={this.state.showAddGroupDialog}
          title={
            this.state.selectedGroupId
              ? configJSON.editGroup
              : configJSON.createGroup
          }
          doneTitle={
            this.state.selectedGroupId ? configJSON.save : configJSON.create
          }
          content={DialogContent}
          onClose={this.handleDialogChange}
          disableSave={!this.state.contact_group.name}
          onDone={this.handleDialogDone}
        />
        <DeleteConfirmationDialog
          open={this.state.bulkDeleteConfirmation}
          onDone={this.handleAllDelete}
          onClose={this.handleBulkDeleteConfirmationDialog}
        />
        <DeleteConfirmationDialog
          open={!!this.state.deleteConfirmationGroupId}
          onDone={() =>
            this.handleDeleteGroup(this.state.deleteConfirmationGroupId)
          }
          onClose={() => this.handleDeleteConfirmationGroupDialog(0)}
        />
        <DeleteConfirmationDialog
          open={!!this.state.deleteConfirmationContactId}
          onDone={() =>
            this.handleDeleteContact(this.state.deleteConfirmationContactId)
          }
          onClose={() => this.handleDeleteConfirmationContactDialog(0)}
        />
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(() => ({
  gap: "20px",
  display: "grid",
  "& .textAlignEnd": {
    textAlign: "end",
    "& .MuiIconButton-sizeSmall": {
      margin: "0 10px",
    },
  },
  "& .textAlignCenter": {
    textAlign: "center",
  },
  "& .borderNone": {
    border: "none",
  },
  "& .headerBox": {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap",
    "& .MuiInputBase-input": {
      width: "180px",
    },
  },
  "& .addressBookHeader": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
    padding: "5px",
  },
  "& .addressBookHeaderTitle": {
    color: "#281D19",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "32px",
  },
  "& .addressBookHeaderButton": {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    borderRadius: 8,
    padding: "10px 16px",
    "&:disabled": {
      color: "#64748B",
      backgroundColor: "#F1F5F9",
      borderColor: "#F1F5F9",
    },
  },
  "& .addressBookDataContainer": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  "& .addressNoDataInnerContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: 10,
    margin: 50,
  },
  "& .noDataImage": {
    marginBottom: 30,
    width: 238,
    height: 232,
  },
  "& .addressNoDataMainText": {
    fontWeight: 700,
    fontSize: 16,
  },
  "& .addressNoDataSubText": {
    fontSize: 14,
  },
  "& .addressBookActionTabContainer": {
    display: "flex",
    fontFamily: "Poppins",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    padding: "8px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    overflow: "auto",
    "& .tabContainer": {
      padding: "0",
      "& .tabBox": {
        display: "flex",
        height: "100%",
      },
    },
    "& .tabItem": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "48px",
      padding: "16px 8px 0px 8px",
      width: "50%",
    },
    "& .labelAction": {
      color: "#334155",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Poppins",
    },
    "& .underLine": {
      background: "#0099FF",
      borderRadius: "8px 8px 0px 0px",
      height: "4px",
      width: "100%",
    },
    "& .tabItem-disable": {
      "& .underLine": {
        display: "none",
      },
      "& .labelAction": {
        color: "#94A3B8",
      },
    },
  },
  "& .actionContainer": {
    padding: "0",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
  },
  "& .tableHeader": {
    color: "#334155",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "24px",
    maxWidth: "150px",
  },
  "& .tableRow": {
    color: "#0F172A",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "24px",
    maxWidth: "150px",
    wordBreak: "break-all",
  },
  "& .actionButton": {
    margin: "0px 12px",
  },
}));

const webStyle = {
  groupNameLabel: {
    color: "#334155",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: "4px",
  },
} as const;

interface TableComponentProps {
  contactList: GetContactData;
  selectedMode: boolean;
  updateSelectedContactData: (id: number) => void;
  handlePageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  goToNavigation: (path: string, id: number) => void;
  handleDeleteConfirmationContactDialog: (id: number) => void;
  handleSelectOption: () => void;
  handleToggleAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseAction: () => void;
  handleIfCondition: (
    condition: boolean,
    trueValue: any,
    falseValue: any
  ) => any;
  webStyle: { [key: string]: React.CSSProperties };
  openId: string | null;
  perPage: number;
}

const TableComponent: React.FC<TableComponentProps> = memo((props) => {
  const {
    contactList,
    selectedMode,
    updateSelectedContactData,
    handlePageChange,
    handleRowsPerPageChange,
    goToNavigation,
    handleDeleteConfirmationContactDialog,
    handleSelectOption,
    handleToggleAction,
    handleCloseAction,
    handleIfCondition,
    webStyle,
    openId,
    perPage,
  } = props;

  return (
    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
      <Table>
        <TableHead>
          <TableRow>
            {selectedMode ? <TableCell className="tableHeader" /> : <></>}
            <TableCell className="tableHeader textAlignCenter">
              {configJSON.name}
            </TableCell>
            <TableCell className="tableHeader textAlignCenter">
              {configJSON.company}
            </TableCell>
            <TableCell className="tableHeader">{configJSON.contact}</TableCell>
            <TableCell className="tableHeader">{configJSON.emailId}</TableCell>
            <TableCell className="tableHeader">{configJSON.group}</TableCell>
            <TableCell className="tableHeader textAlignEnd">
              {configJSON.action}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contactList.contacts.data.map((contact) => (
            <TableRow key={contact.id} data-test-id="contactTableRow">
              {selectedMode ? (
                <TableCell component="th" scope="row">
                  <StyledCheckbox
                    onClick={() => updateSelectedContactData(contact.id)}
                    data-test-id={`checkbox-delete`}
                    checked={contact.isSelected}
                  />
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell
                component="th"
                scope="row"
                data-test-id="contactName"
                className="tableRow textAlignCenter"
              >
                {contact.attributes.name}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className="tableRow textAlignCenter"
              >
                {handleIfCondition(
                  !!contact.attributes.company,
                  contact.attributes.company,
                  "-"
                )}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {contact.attributes.contact_number}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {handleIfCondition(
                  !!contact.attributes.email,
                  contact.attributes.email,
                  "-"
                )}
              </TableCell>
              <TableCell component="th" scope="row" className="tableRow">
                {handleIfCondition(
                  !!contact.attributes.group_name,
                  contact.attributes.group_name,
                  configJSON.unassigned
                )}
              </TableCell>
              <TableCell className="tableRow textAlignEnd">
                <ActionButton
                  data-test-id="contactCallButton"
                  name={`call${contact.id}`}
                  size="small"
                >
                  <img src={images.call} />
                </ActionButton>
                <ActionButton
                  data-test-id="contactChatButton"
                  name={`chat${contact.id}`}
                  size="small"
                >
                  <img src={images.chat} />
                </ActionButton>
                <ClickAwayListener
                  mouseEvent={
                    handleIfCondition(
                      openId === `${contact.id}`,
                      "onClick",
                      false
                    ) as "onClick" | false
                  }
                  onClickAway={handleCloseAction}
                >
                  <CustomTooltip
                    interactive
                    open={`${contact.id}` === openId}
                    placement="left-start"
                    title={
                      <React.Fragment>
                        <Button
                          data-test-id="edit-contact"
                          style={webStyle.tooltipButton}
                          onClick={() =>
                            goToNavigation("EditContact", contact.id)
                          }
                        >
                          {configJSON.edit}
                        </Button>
                        <Button
                          data-test-id="select-contact"
                          style={webStyle.tooltipButton}
                          onClick={handleSelectOption}
                        >
                          {configJSON.select}
                        </Button>
                        <Button
                          data-test-id="delete-contact"
                          style={{
                            ...webStyle.tooltipButton,
                            ...webStyle.redText,
                          }}
                          onClick={() =>
                            handleDeleteConfirmationContactDialog(contact.id)
                          }
                        >
                          {configJSON.delete}
                        </Button>
                      </React.Fragment>
                    }
                  >
                    <ActionButton
                      data-test-id="contactActionButton"
                      name={`${contact.id}`}
                      size="small"
                      onClick={handleToggleAction}
                      style={{
                        background: handleIfCondition(
                          `${contact.id}` === openId,
                          "#0099FF",
                          "white"
                        ) as string,
                      }}
                    >
                      <MoreVert
                        fontSize="medium"
                        style={{
                          zIndex: 2,
                          color: handleIfCondition(
                            `${contact.id}` === openId,
                            "white",
                            "#0F172A"
                          ) as string,
                        }}
                      />
                    </ActionButton>
                  </CustomTooltip>
                </ClickAwayListener>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              className="borderNone"
              colSpan={8}
              count={contactList.total_contacts}
              rowsPerPage={perPage}
              page={contactList.current_page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
});
// Customizable Area End
