import React from "react";
// Customizable Area Start
import { Box, Button, Link, TextField, styled } from "@material-ui/core";
import HeaderLanding from "../../dashboard/src/HeaderLanding.web";

import LandingPageController, {
  IBenefitData,
  Props,
  images,
} from "./LandingPageController";
import Footer from "./Footer.web";
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <HeaderLanding navigation={this.props.navigation} />
        <MainContainer data-test-id="main-landing-page">
          <div className="heading-section">
            <div className="heading-div">
              <div className="heading-left-div">
                <div className="heading-left-text">
                  {this.state.headerResponse.company_heading}
                </div>
                <div className="heading-left-text-1">
                  {this.state.headerResponse.company_subheading}
                </div>
                <div className="heading-left-input-email">
                  <div className="input-email">
                    <TextField
                      data-test-id="email-input"
                      id="email"
                      fullWidth
                      placeholder="Enter your email"
                      value={this.state.emailValue}
                      onChange={this.handleEmailChange}
                    />
                  </div>
                  <div>
                    <Button
                      data-test-id="try-for-free-btn"
                      variant="contained"
                      onClick={this.handleTryForFree}
                      disabled={!this.state.emailValue}
                    >
                      Try for Free
                    </Button>
                  </div>
                </div>
                <div className="heading-left-text-2">
                  {" "}
                  Full access. No credit card required.
                </div>
              </div>
              <div className="heading-right-div">
                <img src={images.headingImage} />
              </div>
            </div>
            {!!this.state.logoImagesResponse.length && (
              <div className="customers-div">
                <div className="customers-text">
                  Trusted by 1,000+ customers
                </div>
                <div className="customers-images">
                  {this.state.logoImagesResponse.map((logo) => (
                    <img
                      key={logo.id}
                      src={logo.attributes.logo_image}
                      alt={logo.type}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="platform-section">
            <div className="platform-text">
              {this.state.benefitResponse.title}
            </div>
            <div className="platform-text-1">
              {this.state.benefitResponse.description}
            </div>
            <div className="platform-card-section">
              {this.state.benefitResponse.benefit_cards.map((card, i) => (
                <BenefitCard
                  key={card.id}
                  benefitData={card}
                  images={this.benefitStaticData[i]}
                  data-test-id="benefit-card"
                />
              ))}
            </div>
          </div>
          {this.state.textImageResponse.length ? (
            <div className="sms-section">
              {this.state.textImageResponse.map((text, i) => (
                <div key={text.id} className="sms-div">
                  <div className={`sms-left-div ${i === 1 && "second"}`}>
                    <div className="sms-left-text">{text.category}</div>
                    <div className="sms-left-text-1">{text.heading}</div>
                    <div className="sms-left-text-2">{text.description}</div>
                    <div className="sms-left-points">
                      {text.pointer_1 && (
                        <div className="sms-left-text-2">
                          <img src={images.rightIcon} />
                          {text.pointer_1}
                        </div>
                      )}
                      {text.pointer_2 && (
                        <div className="sms-left-text-2">
                          <img src={images.rightIcon} />
                          {text.pointer_2}
                        </div>
                      )}
                      {text.pointer_3 && (
                        <div className="sms-left-text-2">
                          <img src={images.rightIcon} />
                          {text.pointer_3}
                        </div>
                      )}
                      {text.pointer_4 && (
                        <div className="sms-left-text-2">
                          <img src={images.rightIcon} />
                          {text.pointer_4}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sms-right-div">
                    <img src={this.textImageStaticData[i].image} />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
          {this.state.logoIntegrationResponse.length ? (
            <div className="integration-section">
              <div className="integration-text">Over 300+ integrations</div>
              <div className="integration-text-1">
                Expand the capabilities of Yoora with hundreds of apps and
                integrations
              </div>
              <div className="integration-images">
                {this.state.logoIntegrationResponse.map((logo) => (
                  <div key={logo.id} className="integration-images-div">
                    <img src={logo.attributes.logo_image} />
                  </div>
                ))}
              </div>
              <div className="integration-text-2">
                See all apps and extensions <img src={images.rightArrow} />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="new-result-section">
            {this.state.blogListingResponse.length > 1 ? (
              <>
                <div className="new-heading-div">
                  <div className="new-text">What’s new at Callifony?</div>
                  <Button
                    className="new-text-1"
                    data-test-id="see-all-button"
                    onClick={this.goToBlogListing}
                  >
                    See all <img src={images.rightArrow} />
                  </Button>
                </div>
                <div className="new-service">
                  {this.state.blogListingResponse.map((blog) => (
                    <div key={blog.id} className="new-service-div">
                      <div className="new-service-text">
                        {blog.category_name}
                      </div>
                      <div className="new-service-img">
                        <img src={blog.images_and_videos} />
                      </div>
                      <div className="new-service-text-1">{blog.name}</div>
                      <div className="new-service-text-2">
                        {blog.created_at}, <span> by {blog.author_name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
            {this.state.resultsRevenueResponse.length ? (
              <>
                <div className="result-text">Real-life results and revenue</div>
                <div className="result-text-1">
                  See how companies like yours have smashed their sales success
                  goals
                </div>
                <div className="result-list">
                  {this.state.resultsRevenueResponse.map((result) => (
                    <div key={result.id} className="result-div">
                      <div className="result-left-div">
                        <div className="result-left-text">
                          {result.revenue_number}
                        </div>
                        <div className="result-left-text-1">
                          {result.revenue_tag}
                        </div>
                      </div>
                      <div className="result-right-div">
                        <div className="result-right-text">
                          {result.description}
                        </div>
                        <div className="profile">
                          <img
                            src={
                              result.profile_image_url || images.defaultAvatar
                            }
                          />
                          <div className="profile-div">
                            <div className="profile-text">
                              {result.author_name}
                            </div>
                            <div className="profile-text-1">
                              {result.author_description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {this.state.customerFeedbackResponse.length ? (
            <div className="customer-section">
              <div className="customer-text">
                We love our Customers and They love us too
              </div>
              <div className="customer-card-list">
                {this.state.customerFeedbackResponse.map((customer) => (
                  <div key={customer.id} className="customer-card">
                    <div>
                      <div className="customer-card-text">“</div>
                      <div className="customer-card-text-1">
                        {customer.description}
                      </div>
                    </div>
                    <div className="profile">
                      <img
                        src={customer.profile_image_url || images.defaultAvatar}
                      />
                      <div className="profile-div">
                        <div className="profile-text">
                          {customer.author_name}
                        </div>
                        <div className="profile-text-1">
                          {customer.designation}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="supercharge-section">
            <div className="supercharge-left-div">
              <div className="supercharge-text">
                Ready to supercharge your business?
              </div>
              <div className="supercharge-text-1">
                Ask about Yoora products, pricing, implementation, or anything
                else. Our highly trained reps are standing by, ready to help.
              </div>
            </div>
            <div className="supercharge-right-div">
              <Button variant="contained" onClick={this.handleTryForFree}>
                Try for Free <img src={images.rightArrowBlack} />
              </Button>
              <div className="supercharge-text">
                Full access. No credit card required.
              </div>
              <img src={images.shape} className="supercharge-img" />
            </div>
          </div>
          <Footer />
        </MainContainer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .heading-section": {
    padding: "70px 0 84px 150px",
    background: "#F4F4F4",
    "& .heading-div": {
      display: "flex",
      gap: "45px",
      alignItems: "center",
      "& .heading-left-div": {
        width: "53%",
        "& .heading-left-text": {
          color: "black",
          fontSize: "64px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          lineHeight: "74px",
          marginBottom: "24px",
        },
        "& .heading-left-text-1": {
          color: "black",
          fontSize: "18px",
          fontFamily: "Satoshi",
          fontWeight: "400",
          lineHeight: "28px",
          marginBottom: "64px",
          textAlign: "justify",
        },
        "& .heading-left-input-email": {
          maxWidth: "460px",
          background: "white",
          borderRadius: "12px",
          border: "1px #DCDCDC solid",
          padding: "8px",
          marginBottom: "12px",
          display: "flex",
          alignItems: "center",
          "& .input-email": {
            width: "100%",
            "& .MuiInputBase-input": {
              padding: "16px",
              color: "#787878",
              fontSize: "18px",
              fontFamily: "Satoshi",
              fontWeight: "500",
            },
            "& .MuiInput-underline:before": {
              display: "none",
            },
            "& .MuiInput-underline:after": {
              display: "none",
            },
          },
          "& .MuiButton-contained": {
            boxShadow: "none",
            background: "#0099FF",
            borderRadius: "8px",
            padding: "16px 24px",
            whiteSpace: "nowrap",
            color: "white",
            fontSize: "18px",
            fontFamily: "Satoshi",
            fontWeight: "700",
            lineHeight: "18px",
            textTransform: "none",
            "&.Mui-disabled": {
              background: "#E2E8F0",
              color: "#334155",
            },
          },
        },
        "& .heading-left-text-2": {
          color: "#404040",
          fontSize: "14px",
          fontFamily: "Satoshi",
          fontWeight: "400",
          paddingLeft: "10px",
        },
      },
      "& .heading-right-div": {
        overflow: "hidden",
        width: "45%",
        "& img": {
          height: "482px",
        },
      },
    },
    "& .customers-div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "120px",
      marginTop: "130px",
      marginRight: "150px",
      "& .customers-text": {
        color: "black",
        fontSize: "16px",
        fontFamily: "Satoshi",
        fontWeight: "500",
        whiteSpace: "nowrap",
      },
      "& .customers-images": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "60px",
        width: "100%",
        "& img": {
          maxWidth: "130px",
          maxHeight: "32px",
        },
      },
    },
  },
  "& .platform-section": {
    padding: "120px 150px 92px",
    background: "linear-gradient(247deg, #1676C0 0%, #13A9EC 100%)",
    "& .platform-text": {
      color: "white",
      fontSize: "48px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      marginBottom: "11px",
    },
    "& .platform-text-1": {
      color: "#F4F4F4",
      fontSize: "18px",
      fontFamily: "Satoshi",
      fontWeight: "400",
      lineHeight: "28px",
      marginBottom: "74px",
      width: "50%",
      textAlign: "justify",
    },
    "& .platform-card-section": {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      gap: "9px",
      "& .platform-card-div": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        background: "rgba(255,255,255, 0.04)",
        borderRadius: "12px",
        padding: "64px 32px",
        width: "100%",
        transition: "0.2s",
        "& .platform-image": {
          height: "100%",
          maxHeight: "72px",
          marginBottom: "56px",
        },
        "& img": {
          maxWidth: "72px",
          maxHeight: "72px",
        },
        "& .platform-hr": {
          background: "rgba(255,255,255, 0.24)",
          marginBottom: "16px",
          height: "1px",
          width: "100%",
        },
        "& .platform-card-text": {
          color: "white",
          fontSize: "20px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          letterSpacing: "0.38px",
          marginBottom: "10px",
        },
        "& .platform-card-text-1": {
          color: "white",
          fontSize: "16px",
          fontFamily: "Satoshi",
          fontWeight: "400",
          lineHeight: "26px",
          textAlign: "justify",
        },
        "&:hover": {
          background: "rgba(255,255,255, 1)",
          marginTop: "-12px",
          marginBottom: "12px",
          "& .platform-hr": {
            background: "rgba(0,0,0, 0.72)",
          },
          "& .platform-card-text": {
            color: "black",
          },
          "& .platform-card-text-1": {
            color: "black",
          },
        },
      },
    },
  },
  "& .sms-section": {
    padding: "120px 150px",
    display: "flex",
    flexDirection: "column",
    gap: "120px",
    "& .sms-div": {
      borderTop: "1px solid #787878",
      paddingTop: "48px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "98px",
      "& .sms-left-div": {
        padding: "44px 0",
        width: "45%",
        "&.second": {
          order: 1,
        },
        "& .sms-left-text": {
          background: "#FFE377",
          borderRadius: "4px",
          padding: "7px 9px",
          color: "#404040",
          fontSize: "14px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          textTransform: "uppercase",
          letterSpacing: "1.40px",
          width: "fit-content",
          marginBottom: "16px",
        },
        "& .sms-left-text-1": {
          color: "black",
          fontSize: "48px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          lineHeight: "58px",
          marginBottom: "24px",
        },
        "& .sms-left-text-2": {
          color: "black",
          fontSize: "18px",
          fontFamily: "Satoshi",
          fontWeight: "400",
          lineHeight: "28px",
          letterSpacing: "0.20px",
          display: "flex",
          alignItems: "baseline",
          gap: "24px",
          textAlign: "justify",
        },
        "& .sms-left-points": {
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        },
      },
      "& .sms-right-div": {
        "& img": {
          maxWidth: "555px",
          width: "100%",
        },
      },
    },
  },
  "& .integration-section": {
    padding: "120px 150px",
    background: "#F4F4F4",
    textAlign: "center",
    "& .integration-text": {
      color: "black",
      fontSize: "48px",
      fontFamily: "Satoshi",
      fontWeight: "700",
      lineHeight: "68px",
      marginBottom: "12px",
    },
    "& .integration-text-1": {
      color: "#3B3A40",
      fontSize: "18px",
      fontFamily: "Satoshi",
      fontWeight: "400",
      lineHeight: "28px",
      marginBottom: "72px",
    },
    "& .integration-text-2": {
      color: "black",
      fontSize: "24px",
      fontFamily: "Satoshi",
      fontWeight: "500",
      letterSpacing: "0.51px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "4px",
    },
    "& .integration-images": {
      marginBottom: "72px",
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      gap: "8px",
      "& .integration-images-div": {
        background: "white",
        borderRadius: "12px",
        padding: "24px",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        "& img": {
          maxWidth: "220px",
          maxHeight: "72px",
        },
      },
    },
  },
  "& .new-result-section": {
    padding: "120px 150px 180px",
    "& .new-heading-div": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      gap: "20px",
      marginBottom: "72px",
      "& .new-text": {
        color: "black",
        fontSize: "64px",
        fontFamily: "Inter",
        fontWeight: "700",
        lineHeight: "74px",
      },
      "& .new-text-1": {
        color: "black",
        fontSize: "20px",
        fontFamily: "Satoshi",
        fontWeight: "500",
        letterSpacing: "0.43px",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        cursor: "pointer",
        textTransform: "none",
        padding: "0",
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    "& .new-service": {
      display: "flex",
      justifyContent: "space-between",
      gap: "30px",
      marginBottom: "240px",
      "& .new-service-div": {
        width: "50%",
        "& .new-service-text": {
          color: "#787878",
          fontSize: "14px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          textTransform: "uppercase",
          letterSpacing: "1.40px",
          paddingBottom: "14px",
          marginBottom: "24px",
          borderBottom: "1px solid #787878",
        },
        "& .new-service-img": {
          marginBottom: "24px",
          maxWidth: "555px",
          maxHeight: "370px",
          overflow: "hidden",
          "& img": {
            width: "100%",
          },
        },
        "& .new-service-text-1": {
          color: "black",
          fontSize: "32px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          lineHeight: "42px",
          marginBottom: "24px",
        },
        "& .new-service-text-2": {
          color: "black",
          fontSize: "16px",
          fontFamily: "Satoshi",
          fontWeight: "500",
          "& span": {
            color: "#787878",
          },
        },
      },
    },
    "& .result-text": {
      color: "black",
      fontSize: "48px",
      fontFamily: "Satoshi",
      fontWeight: "700",
      lineHeight: "68px",
      marginBottom: "12px",
    },
    "& .result-text-1": {
      color: "#3B3A40",
      fontSize: "18px",
      fontFamily: "Satoshi",
      fontWeight: "400",
      lineHeight: "28px",
      marginBottom: "72px",
    },
    "& .result-list": {
      display: "grid",
      gap: "72px",
      "& .result-div": {
        paddingTop: "48px",
        borderTop: "1px solid #787878",
        display: "flex",
        justifyContent: "space-between",
        gap: "30px",
        "& .result-left-div": {
          "& .result-left-text": {
            color: "black",
            fontSize: "72px",
            fontFamily: "Satoshi",
            fontWeight: "700",
            lineHeight: "74px",
            marginBottom: "24px",
          },
          "& .result-left-text-1": {
            color: "black",
            fontSize: "20px",
            fontFamily: "Satoshi",
            fontWeight: "700",
          },
        },
        "& .result-right-div": {
          width: "68%",
          "& .result-right-text": {
            color: "black",
            fontSize: "32px",
            fontFamily: "Satoshi",
            fontWeight: "500",
            lineHeight: "42px",
            marginBottom: "32px",
            textAlign: "justify",
          },
        },
      },
    },
  },
  "& .customer-section": {
    padding: "120px 150px",
    background: "#F4F4F4",
    "& .customer-text": {
      width: "50%",
      color: "black",
      fontSize: "48px",
      fontFamily: "Satoshi",
      fontWeight: "700",
      lineHeight: "58px",
      marginBottom: "72px",
    },
    "& .customer-card-list": {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      gap: "30px",
      "& .customer-card": {
        width: "30%",
        display: "grid",
        alignContent: "space-between",
        "& .customer-card-text": {
          color: "#1777C1",
          fontSize: "120px",
          fontFamily: "Satoshi",
          fontWeight: "700",
          lineHeight: "80px",
          marginBottom: "-24px",
        },
        "& .customer-card-text-1": {
          color: "black",
          fontSize: "24px",
          fontFamily: "Satoshi",
          fontWeight: "500",
          lineHeight: "34px",
          marginBottom: "48px",
          borderTop: "1px solid #787878",
          paddingTop: "24px",
          textAlign: "justify",
        },
      },
    },
  },
  "& .supercharge-section": {
    background: "#24323D",
    display: "flex",
    alignItems: "stretch",
    "& .supercharge-left-div": {
      padding: "120px 0 120px 150px",
      width: "50%",
      "& .supercharge-text": {
        color: "white",
        fontSize: "64px",
        fontFamily: "Satoshi",
        fontWeight: "700",
        lineHeight: "74px",
        marginBottom: "24px",
      },
      "& .supercharge-text-1": {
        color: "white",
        fontSize: "18px",
        fontFamily: "Satoshi",
        fontWeight: "500",
        lineHeight: "28px",
        textAlign: "justify",
      },
    },
    "& .supercharge-right-div": {
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "24px",
      position: "relative",
      overflow: "hidden",
      "& .MuiButton-contained": {
        boxShadow: "none",
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "20px 24px 20px 32px",
        whiteSpace: "nowrap",
        color: "black",
        fontSize: "18px",
        fontFamily: "Satoshi",
        fontWeight: "700",
        textTransform: "none",
        zIndex: 2,
        "& .MuiButton-label": {
          display: "flex",
          alignItems: "center",
          gap: "24px",
        },
      },
      "& .supercharge-text": {
        color: "white",
        fontSize: "14px",
        fontFamily: "Satoshi",
        fontWeight: "500",
        zIndex: 2,
      },
      "& .supercharge-img": {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "100%",
      },
    },
  },
  "& .profile": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "& img": {
      maxWidth: "48px",
      maxHeight: "48px",
      borderRadius: "50%",
    },
    "& .profile-div": {
      "& .profile-text": {
        color: "black",
        fontSize: "16px",
        fontFamily: "Satoshi",
        fontWeight: "700",
        marginBottom: "2px",
      },
      "& .profile-text-1": {
        color: "#404040",
        fontSize: "16px",
        fontFamily: "Satoshi",
        fontWeight: "400",
      },
    },
  },
  "@media (max-width:1279px)": {
    "& .heading-section": {
      "& .customers-div": {
        flexWrap: "wrap",
        gap: "30px !important",
      },
    },
    "& .customer-section": {
      "& .customer-text": {
        width: "auto !important",
      },
    },
  },
  "@media (max-width:959px)": {
    "& .heading-section": {
      padding: "70px 0 84px 70px",
      "& .heading-div": {
        flexDirection: "column",
        "& .heading-left-div": {
          width: "auto !important",
          marginRight: "70px",
          order: 1,
        },
        "& .heading-right-div": {
          width: "100% !important",
        },
      },
      "& .customers-div": {
        marginRight: "70px !important",
        "& .customers-images": {
          flexWrap: "wrap",
        },
      },
    },
    "& .platform-section": {
      padding: "60px 70px 45px",
      "& .platform-text-1": {
        width: "auto !important",
      },
      "& .platform-card-section": {
        flexDirection: "column",
        gap: "21px !important",
        "& .platform-card-div": {
          width: "auto !important",
        },
      },
    },
    "& .sms-section": {
      padding: "60px 70px",
      gap: "60px",
      "& .sms-div": {
        gap: "48px !important",
        flexDirection: "column",
        "& .sms-left-div": {
          padding: "0 !important",
          width: "auto !important",
          order: 1,
        },
        "& .sms-right-div": {
          order: 0,
        },
      },
    },
    "& .integration-section": {
      padding: "60px 70px",
    },
    "& .new-result-section": {
      padding: "60px 70px 90px",
      "& .new-heading-div": {
        flexDirection: "column",
      },
      "& .new-service": {
        flexDirection: "column",
        gap: "60px !important",
        marginBottom: "120px !important",
        "& .new-service-div": {
          maxWidth: "auto !important",
          width: "auto !important",
        },
      },
      "& .result-list": {
        "& .result-div": {
          flexDirection: "column",
          "& .result-right-div": {
            width: "auto !important",
          },
        },
      },
    },
    "& .customer-section": {
      padding: "60px 70px",
      "& .customer-card-list": {
        gap: "90px !important",
        flexDirection: "column",
        "& .customer-card": {
          width: "auto !important",
        },
      },
    },
    "& .supercharge-section": {
      flexDirection: "column",
      "& .supercharge-left-div": {
        padding: "60px 70px !important",
        width: "auto !important",
        zIndex: 2,
      },
      "& .supercharge-right-div": {
        width: "auto !important",
        overflow: "visible !important",
        height: "360px",
      },
    },
  },
  "@media (max-width:600px)": {
    "& .heading-section": {
      padding: "30px 0 40px 30px",
      "& .heading-div": {
        gap: "25px !important",
        "& .heading-left-div": {
          marginRight: "30px !important",
          "& .heading-left-text": {
            fontSize: "32px !important",
            lineHeight: "37px !important",
            marginBottom: "12px !important",
          },
          "& .heading-left-text-1": {
            marginBottom: "32px !important",
          },
          "& .heading-left-input-email": {
            padding: "4px !important",
            marginBottom: "6px !important",
            "& .input-email": {
              "& .MuiInputBase-input": {
                padding: "8px !important",
              },
            },
            "& .MuiButton-contained": {
              padding: "8px 12px !important",
            },
          },
        },
        "& .heading-right-div": {
          "& img": {
            height: "250px !important",
          },
        },
      },
      "& .customers-div": {
        gap: "20px !important",
        marginTop: "60px !important",
        marginRight: "30px !important",
        "& .customers-images": {
          gap: "30px !important",
        },
      },
    },
    "& .platform-section": {
      padding: "30px",
      "& .platform-text": {
        fontSize: "30px !important",
        marginBottom: "8px !important",
      },
      "& .platform-text-1": {
        marginBottom: "37px !important",
      },
      "& .platform-card-section": {
        "& .platform-card-div": {
          padding: "32px 16px !important",
          "& img": {
            marginBottom: "28px !important",
          },
          "& .platform-hr": {
            marginBottom: "8px !important",
          },
          "& .platform-card-text": {
            marginBottom: "8px !important",
          },
        },
      },
    },
    "& .sms-section": {
      padding: "30px",
      gap: "30px",
      "& .sms-div": {
        paddingTop: "24px !important",
        gap: "24px !important",
        "& .sms-left-div": {
          "& .sms-left-text": {
            padding: "5px !important",
            marginBottom: "8px !important",
          },
          "& .sms-left-text-1": {
            fontSize: "24px !important",
            lineHeight: "29px !important",
            marginBottom: "16px !important",
          },
          "& .sms-left-text-2": {
            gap: "16px !important",
            "& img": {
              width: "10px",
            },
          },
          "& .sms-left-points": {
            marginTop: "12px !important",
            gap: "10px !important",
          },
        },
      },
    },
    "& .integration-section": {
      padding: "30px",
      "& .integration-text": {
        fontSize: "28px !important",
        lineHeight: "34px !important",
        marginBottom: "8px !important",
      },
      "& .integration-text-1": {
        marginBottom: "36px !important",
      },
      "& .integration-text-2": {
        fontSize: "18px !important",
      },
      "& .integration-images": {
        marginBottom: "36px !important",
        "& .integration-images-div": {
          justifyContent: "center",
          padding: "12px 0 !important",
          gap: "6px !important",
          "& img": {
            width: "50%",
          },
        },
      },
    },
    "& .new-result-section": {
      padding: "30px 30px 45px",
      "& .new-heading-div": {
        gap: "16px !important",
        marginBottom: "36px  !important",
        "& .new-text": {
          fontSize: "32px !important",
          lineHeight: "37px !important",
        },
        "& .new-text-1": {
          fontSize: "16px !important",
        },
      },
      "& .new-service": {
        gap: "30px !important",
        marginBottom: "60px !important",
        "& .new-service-div": {
          "& .new-service-text": {
            paddingBottom: "7px !important",
            marginBottom: "12px !important",
          },
          "& .new-service-img": {
            marginBottom: "12px !important",
          },
          "& .new-service-text-1": {
            fontSize: "20px !important",
            lineHeight: "21px !important",
            marginBottom: "12px !important",
          },
        },
      },
      "& .result-text": {
        fontSize: "30px !important",
        lineHeight: "34px !important",
        marginBottom: "6px !important",
      },
      "& .result-text-1": {
        marginBottom: "36px !important",
      },
      "& .result-list": {
        gap: "36px !important",
        "& .result-div": {
          paddingTop: "24px !important",
          gap: "16px !important",
          "& .result-left-div": {
            "& .result-left-text": {
              fontSize: "36px !important",
              lineHeight: "37px !important",
              marginBottom: "12px !important",
            },
          },
          "& .result-right-div": {
            "& .result-right-text": {
              fontSize: "20px !important",
              lineHeight: "24px !important",
              marginBottom: "16px !important",
            },
          },
        },
      },
    },
    "& .customer-section": {
      padding: "30px",
      "& .customer-text": {
        fontSize: "28px !important",
        lineHeight: "30px !important",
        marginBottom: "36px !important",
      },
      "& .customer-card-list": {
        gap: "45px !important",
        "& .customer-card": {
          "& .customer-card-text": {
            fontSize: "60px !important",
            lineHeight: "40px !important",
            marginBottom: "-12px !important",
          },
          "& .customer-card-text-1": {
            fontSize: "20px !important",
            lineHeight: "22px !important",
            marginBottom: "24px !important",
            paddingTop: "12px !important",
          },
        },
      },
    },
    "& .supercharge-section": {
      "& .supercharge-left-div": {
        padding: "30px !important",
        "& .supercharge-text": {
          fontSize: "36px !important",
          lineHeight: "40px !important",
          marginBottom: "12px !important",
        },
      },
      "& .supercharge-right-div": {
        height: "200px !important",
        gap: "12px !important",
        "& .MuiButton-contained": {
          padding: "8px !important",
          "& .MuiButton-label": {
            gap: "12px !important",
          },
        },
      },
    },
    "& .profile": {
      gap: "6px !important",
      "& img": {
        maxWidth: "30px",
        maxHeight: "30px",
      },
      "& .profile-div": {
        "& .profile-text": {
          fontSize: "14px !important",
        },
        "& .profile-text-1": {
          fontSize: "12px !important",
        },
      },
    },
  },
});

const BenefitCard = ({
  benefitData,
  images,
}: {
  benefitData: IBenefitData;
  images: { image: string; selectedImage: string };
}) => {
  const [imageSrc, setImageSrc] = React.useState(images.image);

  const handleHover = () => {
    setImageSrc(images.selectedImage);
  };

  const handleMouseLeave = () => {
    setImageSrc(images.image);
  };

  return (
    <div
      key={benefitData.id}
      className="platform-card-div"
      data-test-id="platform-card"
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <div className="platform-image">
        <img src={imageSrc} />
      </div>
      <div className="platform-hr" />
      <div className="platform-card-text">{benefitData.title}</div>
      <div className="platform-card-text-1">{benefitData.description}</div>
    </div>
  );
};
// Customizable Area End
