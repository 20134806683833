// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { expireTokenHandling } from "../../../components/src/Utilities";

export const configJSON = require("./config");
export const images = require("./assets");

type WrapUpTime = {
  first: number | "";
  second: number | "";
};

type FormData = {
  campaignName: string;
  selectDID: number;
  selectIVR: number;
  enableRecording: boolean;
  enableClick2Call: boolean;
  callBackURL: string;
  blacklistGroup: boolean;
  whitelistGroup: boolean;
  wrapUpTime: WrapUpTime;
  dispositions: number;
  apiInput: string;
  hitScreenPOPTrigger: number;
  screenPOPURL: string;
};

const initialData: FormData = {
  campaignName: "",
  selectDID: 0,
  selectIVR: 0,
  enableRecording: false,
  enableClick2Call: false,
  callBackURL: "",
  blacklistGroup: false,
  whitelistGroup: false,
  wrapUpTime: { first: "", second: "" },
  dispositions: 1,
  apiInput: "",
  hitScreenPOPTrigger: 0,
  screenPOPURL: "",
};

type ErrorData = {
  campaignName: string;
};

const initialErrors: ErrorData = {
  campaignName: "",
};

type IOption = { id: number; name: string };

type DepartmentList = {
  departments: {
    data: {
      id: string;
      attributes: {
        id: number;
        name: string;
      };
    }[];
  };
};

type GetDIDNumberResponse = {
  did_numbers: {
    id: number;
    number: string;
  }[];
};

type GroupList = {
  contact_groups: {
    id: number;
    name: string;
  }[];
};

type SuccessData = {
  message: string;
};

type APIErrorData = {
  errors: string[];
};

export type InBound = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    ivr: string;
    wrapup_time: string;
    screen_popup_url: string;
    enable_recording: boolean;
    enable_click2call: boolean;
    blacklist_group: boolean;
    white_list_group: boolean;
    call_back_url: string;
    dispositions: string;
    api: string;
    hit_screen_pop: string;
    did_number: {
      id: number;
      number: string;
      company_id: number;
    };
    department_ids: number[];
    contact_group_ids: number[];
    created_date: string;
    created_time: string;
    updated_date: string;
    updated_time: string;
  };
  isSelected: boolean;
};

type InBoundResponse = {
  campaigns: {
    data: InBound;
  };
};

type ResponseData =
  | DepartmentList
  | GetDIDNumberResponse
  | GroupList
  | SuccessData
  | APIErrorData
  | InBoundResponse;
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  formData: FormData;
  formErrors: ErrorData;
  departmentList: {
    mainList: IOption[];
    selectedList: IOption[];
  };
  didNumberList: {
    id: number;
    label: string;
  }[];
  groupList: {
    mainList: IOption[];
    selectedList: IOption[];
  };

  editCampaignId: number;
  isComponentMount: {
    department: boolean;
    didNumber: boolean;
    group: boolean;
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddInBoundIVRController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      formData: initialData,
      formErrors: initialErrors,
      departmentList: {
        mainList: [],
        selectedList: [],
      },
      didNumberList: [],
      groupList: {
        mainList: [],
        selectedList: [],
      },

      editCampaignId: 0,
      isComponentMount: {
        department: false,
        didNumber: false,
        group: false,
      },
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  static SELECT_IVR_CONSTANT = [
    {
      id: 1,
      label: "IVR 1",
    },
    {
      id: 2,
      label: "IVR 2",
    },
  ] as const;

  static DISPOSITIONS_CONSTANT = [
    {
      id: 1,
      label: "None (Default)",
    },
    {
      id: 2,
      label: "API",
    },
    {
      id: 3,
      label: "Toolbar",
    },
  ] as const;

  static HIT_SCREEN_POP_TRIGGER_CONSTANT = [
    {
      id: 1,
      label: "Client Side",
    },
    {
      id: 2,
      label: "Server Side",
    },
    {
      id: 3,
      label: "Client & Server",
    },
  ] as const;

  getDepartmentApiCallId: string = "";
  getDIDNumberApiCallId: string = "";
  getGroupApiCallId: string = "";
  crateCampaignApiCallId: string = "";
  getInBoundApiCallId: string = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const editId = message.getData("EditCampaign");
      this.setState({ editCampaignId: Number(editId) });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      expireTokenHandling(responseJson);

      if (apiRequestCallId && responseJson) {
        this.handleReceiveFunction(apiRequestCallId, responseJson);
      }
    }
  }

  async componentDidMount() {
    this.getDepartmentAPICall();
    this.getDIDNumbersAPICall();
    this.getGroupAPICall();
  }

  handleAPICall = async (
    apiEndPoint: string,
    method: string,
    body?: string
  ) => {
    const createApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("authToken"),
    };

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    createApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    if (body) {
      createApiMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(createApiMsg.id, createApiMsg);

    return createApiMsg.messageId;
  };

  handleReceiveFunction = (
    apiRequestCallId: string,
    responseJson: ResponseData
  ) => {
    switch (apiRequestCallId) {
      case this.getDepartmentApiCallId:
        this.receiveGetDepartmentAPICall(responseJson);
        break;
      case this.getDIDNumberApiCallId:
        this.receiveGetDIDNumbersAPICall(responseJson);
        break;
      case this.getGroupApiCallId:
        this.receiveGetGroupAPICall(responseJson);
        break;
      case this.crateCampaignApiCallId:
        this.receiveCreateCampaignAPICall(responseJson);
        break;
      case this.getInBoundApiCallId:
        this.receiveGetInBoundAPICall(responseJson);
        break;
      default:
        break;
    }
  };

  goToNavigation = (route: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(navigationMsg);
  };

  editAPICall = () => {
    if (
      this.state.editCampaignId &&
      this.state.isComponentMount.department &&
      this.state.isComponentMount.didNumber &&
      this.state.isComponentMount.group
    ) {
      this.getInBoundAPICall();
    }
  };

  handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>,
    isSwitch?: boolean,
    checked?: boolean
  ) => {
    const name = event.target.name as keyof FormData;
    let value = event.target.value;
    if (name === "campaignName" && (value as string).length > 40) {
      return;
    }
    if (name === "blacklistGroup" && checked) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          whitelistGroup: false,
        },
      }));
      this.handleGroupChange([]);
    }
    if (name === "whitelistGroup" && checked) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          blacklistGroup: false,
        },
      }));
      this.handleGroupChange([]);
    }
    if (name === "dispositions" && value !== 2) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          apiInput: "",
        },
      }));
    }
    if (isSwitch) {
      value = checked;
    }
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  handleDepartmentChange = (value: (IOption | string)[]) => {
    this.setState((prev) => ({
      ...prev,
      departmentList: {
        ...prev.departmentList,
        selectedList: value as IOption[],
      },
    }));
  };

  handleGroupChange = (value: (IOption | string)[]) => {
    this.setState((prev) => ({
      ...prev,
      groupList: {
        ...prev.groupList,
        selectedList: value as IOption[],
      },
    }));
  };

  handleNumberChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name?: string; value: unknown }>,
    parent: keyof FormData
  ) => {
    const name = event.target.name as keyof FormData;
    const value = event.target.value;
    const regex = new RegExp(/\D/g);
    const newValue = (value as string).replace(regex, "");
    if (newValue.length < 2) {
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          [parent]: {
            ...(prevState.formData[parent] as Object),
            [name]: newValue,
          },
        },
      }));
    }
  };

  some(...values: boolean[]) {
    return values.some((value) => value);
  }

  isSaveBtnDisabled = () => {
    const isBGroupSelected =
      !this.state.formData.blacklistGroup ||
      (this.state.formData.blacklistGroup &&
        this.state.groupList.selectedList.length);

    const isWGroupSelected =
      !this.state.formData.whitelistGroup ||
      (this.state.formData.whitelistGroup &&
        this.state.groupList.selectedList.length);

    return this.some(
      !this.state.formData.campaignName,
      !this.state.formData.selectDID,
      !this.state.formData.selectIVR,
      !this.state.departmentList.selectedList.length,
      !isBGroupSelected,
      !isWGroupSelected
    );
  };

  handleSave = () => {
    const formData = this.state.formData;
    const campaign = {
      name: formData.campaignName,
      did_number_id: formData.selectDID,
      ivr: formData.selectIVR,
      department_ids: this.state.departmentList.selectedList.map(
        (list) => list.id
      ),
      enable_recording: formData.enableRecording,
      enable_click2call: formData.enableClick2Call,
      call_back_url: formData.callBackURL,
      blacklist_group: formData.blacklistGroup,
      white_list_group: formData.whitelistGroup,
      contact_group_ids: this.state.groupList.selectedList.map(
        (list) => list.id
      ),
      wrapup_time: Number(
        `${Number(formData.wrapUpTime.first)}${Number(
          formData.wrapUpTime.second
        )}`
      ),
      dispositions: formData.dispositions,
      api: formData.apiInput,
      hit_screen_pop: formData.hitScreenPOPTrigger,
      screen_popup_url: formData.screenPOPURL,
    };

    this.createCampaignAPICall(JSON.stringify({ campaign }));
  };

  getDepartmentAPICall = async () => {
    this.getDepartmentApiCallId = await this.handleAPICall(
      configJSON.getDepartmentApiEndPoint,
      configJSON.validationApiMethodType
    );
  };

  receiveGetDepartmentAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as DepartmentList;
    if (responseJsonData && responseJsonData.departments) {
      this.setState(
        {
          departmentList: {
            mainList: responseJsonData.departments.data.map((department) => ({
              id: department.attributes.id,
              name: department.attributes.name,
            })),
            selectedList: [],
          },
          isComponentMount: {
            ...this.state.isComponentMount,
            department: true,
          },
        },
        () => this.editAPICall()
      );
    }
  };

  getDIDNumbersAPICall = async () => {
    this.getDIDNumberApiCallId = await this.handleAPICall(
      configJSON.getDIDNumberApiEndPoint,
      configJSON.validationApiMethodType
    );
  };

  receiveGetDIDNumbersAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GetDIDNumberResponse;
    if (responseJsonData && responseJsonData.did_numbers) {
      this.setState(
        {
          didNumberList: responseJsonData.did_numbers.map((number) => ({
            id: number.id,
            label: number.number,
          })),
          isComponentMount: {
            ...this.state.isComponentMount,
            didNumber: true,
          },
        },
        () => this.editAPICall()
      );
    }
  };

  getGroupAPICall = async () => {
    this.getGroupApiCallId = await this.handleAPICall(
      configJSON.getGroupApiEndPoint,
      configJSON.validationApiMethodType
    );
  };

  receiveGetGroupAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as GroupList;
    if (responseJsonData && responseJsonData.contact_groups) {
      this.setState(
        {
          groupList: {
            mainList: responseJsonData.contact_groups.map((group) => ({
              id: group.id,
              name: group.name,
            })),
            selectedList: [],
          },
          isComponentMount: {
            ...this.state.isComponentMount,
            group: true,
          },
        },
        () => this.editAPICall()
      );
    }
  };

  createCampaignAPICall = async (body: string) => {
    this.crateCampaignApiCallId = await this.handleAPICall(
      this.state.editCampaignId
        ? `${configJSON.campaignApiEndPoint}/${this.state.editCampaignId}`
        : configJSON.campaignApiEndPoint,
      this.state.editCampaignId
        ? configJSON.patchAPiMethod
        : configJSON.exampleAPiMethod,
      body
    );
  };

  receiveCreateCampaignAPICall = (responseJson: ResponseData) => {
    if (responseJson && (responseJson as SuccessData).message) {
      this.goToNavigation("Campaign");
    } else if ("errors" in responseJson) {
      let initialAPIErrors: ErrorData = { ...initialErrors };
      for (const value of responseJson.errors) {
        if (value.search("campaign name") >= 0) {
          initialAPIErrors.campaignName = configJSON.nameErrorMessage;
        }
      }
      this.setState((prev) => ({ ...prev, formErrors: initialAPIErrors }));
    }
  };

  getInBoundAPICall = async () => {
    this.getInBoundApiCallId = await this.handleAPICall(
      `${configJSON.campaignApiEndPoint}/${this.state.editCampaignId}`,
      configJSON.validationApiMethodType
    );
  };

  receiveGetInBoundAPICall = (responseJson: ResponseData) => {
    const responseJsonData = responseJson as InBoundResponse;
    if (responseJsonData && responseJsonData.campaigns) {
      const apiData = responseJsonData.campaigns.data.attributes;
      let apiWrapUpTime: WrapUpTime = {
        first: "",
        second: "",
      };
      if (!!Number(apiData.wrapup_time) && Number(apiData.wrapup_time) > 9) {
        apiWrapUpTime = {
          first: Number(apiData.wrapup_time[0]),
          second: Number(apiData.wrapup_time[1]),
        };
      } else if (!!Number(apiData.wrapup_time)) {
        apiWrapUpTime = {
          first: 0,
          second: Number(apiData.wrapup_time[0]),
        };
      }
      this.setState({
        formData: {
          campaignName: apiData.name,
          selectDID: apiData.did_number.id,
          selectIVR: Number(apiData.ivr),
          enableRecording: apiData.enable_recording,
          enableClick2Call: apiData.enable_click2call,
          callBackURL: apiData.call_back_url,
          blacklistGroup: apiData.blacklist_group,
          whitelistGroup: apiData.white_list_group,
          wrapUpTime: apiWrapUpTime,
          dispositions: Number(apiData.dispositions),
          apiInput: apiData.api,
          hitScreenPOPTrigger: Number(apiData.hit_screen_pop),
          screenPOPURL: apiData.screen_popup_url,
        },
        departmentList: {
          ...this.state.departmentList,
          selectedList: this.state.departmentList.mainList.filter((list) =>
            apiData.department_ids.includes(list.id)
          ),
        },
        groupList: {
          ...this.state.groupList,
          selectedList: this.state.groupList.mainList.filter((list) =>
            apiData.contact_group_ids.includes(list.id)
          ),
        },
      });
    }
  };
  // Customizable Area End
}
