// Customizable Area Start
import React from "react";
import { Box, IconButton, styled } from "@material-ui/core";
import HeaderLanding from "../../dashboard/src/HeaderLanding.web";

import BlogDetailsController, {
  Props,
  images,
} from "./BlogDetailsController.web";
import Footer from "../../landingpage/src/Footer.web";
// Customizable Area End

export default class BlogDetails extends BlogDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div id="myref">
        <HeaderLanding
          navigation={this.props.navigation}
          style={{ background: "#ffffff" }}
        />
        <MainContainer data-test-id="main-blogDetails-page">
          <IconButton
            data-test-id="back-button"
            className="arrow-back"
            onClick={this.goToBlogListing}
          >
            <img src={images.arrowBack} />
          </IconButton>
          <div className="blog-img">
            <img src={this.state.blogDetailsResponse.images_and_videos} />
          </div>
          <div className="title">{this.state.blogDetailsResponse.name}</div>
          <div className="blog-text">
            {this.state.blogDetailsResponse.author_name}{" "}
            <span>• {this.state.blogDetailsResponse.created_at}</span>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.blogDetailsResponse.body,
            }}
          />
        </MainContainer>
        <Footer />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding: "0 80px 50px",
  "& .arrow-back": {
    cursor: "pointer",
    padding: "0",
  },
  "& .blog-img": {
    marginTop: "30px",
    marginBottom: "40px",
    maxWidth: "1280px",
    maxHeight: "673px",
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  "& .title": {
    color: "#131212",
    fontFamily: "Outfit",
    fontSize: "30px",
    fontWeight: 500,
    lineHeight: "39.6px",
    marginBottom: "10px",
  },
  "& .blog-text": {
    color: "#131212",
    fontSize: "20px",
    fontFamily: "Outfit",
    fontWeight: "500",
    lineHeight: "28.8px",
    marginBottom: "40px",
    "& span": {
      color: "#716969",
    },
  },
  "@media (max-width:959px)": {
    "& .title": {
      fontSize: "28px !important",
      lineHeight: "37.6px !important",
    },
    "& .blog-text": {
      fontSize: "18px !important",
      lineHeight: "26.8px !important",
    },
  },
  "@media (max-width:600px)": {
    padding: "0 25px 25px",
  },
});
// Customizable Area End
