// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Button,
  styled,
  InputAdornment,
  Select,
  MenuItem,
} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import IvrFlowController, {
  Props,
  images,
  configJSON,
} from "./IvrFlowController";
import Layout from "../../../components/src/Layout.web";
import { InputBox } from "../../groups2/src/Agents.web";
import Loader from "../../../components/src/Loader.web";
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";
// Customizable Area End

export default class IvrFlow extends IvrFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout menuItem="ivrFlow" navigation={this.props.navigation}>
        <Loader loading={this.state.isLoading} />
        <MainContainer data-test-id="ivrFlowComponent">
          <Box
            className="ivrFlowHeader"
            style={{
              background: !!this.state.selectedIds.length ? "white" : "",
            }}
          >
            <Box className="headerBox">
              <img src={images.ivrFlows} width={64} height={48} />
              <Typography className="ivrFlowHeaderTitle">
                {configJSON.ivrFlow}
              </Typography>
              <InputBox
                placeholder="Search"
                type="text"
                data-test-id="searchText"
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ zIndex: 1, marginRight: 0 }}
                  >
                    <SearchRoundedIcon className="search" />
                  </InputAdornment>
                }
                value={this.state.search}
                onChange={(event: { target: { value: string } }) =>
                  this.handleSearch(event.target.value)
                }
              />
            </Box>
            {!!this.state.selectedIds.length ? (
              <Box
                data-test-id="select_delete"
                onClick={this.handleBulkDeleteConfirmationDialog}
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                <DeleteOutlineRoundedIcon
                  style={{
                    color: "rgba(220, 38, 38, 1)",
                    marginRight: "5px",
                  }}
                ></DeleteOutlineRoundedIcon>
                <Box
                  style={{
                    fontWeight: 700,
                    color: "rgba(220, 38, 38, 1)",
                    fontSize: "16px",
                  }}
                >
                  {configJSON.delete}
                </Box>
              </Box>
            ) : null}
            <Box display="flex" gridGap={30}>
              <Button
                data-test-id="add-ivr-flow"
                variant="contained"
                className="ivrFlowHeaderButton"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => this.goToNavigation("AddIvrFlow")}
                disabled={!!this.state.selectedIds.length}
              >
                {configJSON.createIvrFlows}
              </Button>
            </Box>
          </Box>
          <Box className="ivrFlowActionTabContainer">{this.renderView()}</Box>
          {!!this.state.ivrFlowListData.ivr_form.data.length && (
            <div className="download-div">
              <div className="download-text">{configJSON.downloadIvrFlow}</div>
              <SelectDiv
                data-test-id="download-file-type"
                disableUnderline
                id="download-file-type"
                IconComponent={KeyboardArrowDownRoundedIcon}
                value={this.state.downloadFileType}
                onChange={this.handleOnDownloadFileTypeChange}
              >
                <MenuItem value="csv">{configJSON.csv}</MenuItem>
                <MenuItem value="xlsx">{configJSON.xlsx}</MenuItem>
              </SelectDiv>
              <Button
                data-test-id="download-btn"
                className="download-btn"
                onClick={this.handleDownloadBtn}
              >
                <img src={images.download} /> {configJSON.download}
              </Button>
            </div>
          )}
        </MainContainer>
        <DeleteConfirmationDialog
          open={this.state.bulkDeleteConfirmation}
          onDone={() => this.handleDeleteIvrFlow(this.state.selectedIds)}
          onClose={this.handleBulkDeleteConfirmationDialog}
        />
        <DeleteConfirmationDialog
          open={!!this.state.deleteConfirmationId}
          onDone={() =>
            this.handleDeleteIvrFlow(this.state.deleteConfirmationId)
          }
          onClose={() => this.handleDeleteConfirmationDialog(0)}
        />
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(() => ({
  gap: "20px",
  display: "grid",
  "& .borderNone": {
    border: "none",
  },
  "& .headerBox": {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap",
  },
  "& .ivrFlowHeader": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
    padding: "5px",
  },
  "& .ivrFlowHeaderTitle": {
    color: "#281D19",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "32px",
  },
  "& .ivrFlowHeaderButton": {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    borderRadius: 8,
    padding: "10px 16px",
    "&:disabled": {
      color: "#64748B",
      backgroundColor: "#F1F5F9",
      borderColor: "#F1F5F9",
    },
  },
  "& .ivrFlowDataContainer": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  "& .ivrFlowDataInnerContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: 10,
    margin: 50,
  },
  "& .noDataImage": {
    marginBottom: 30,
    width: 238,
    height: 232,
  },
  "& .ivrFlowDataMainText": {
    fontWeight: 700,
    fontSize: 16,
  },
  "& .ivrFlowDataSubText": {
    fontSize: 14,
  },
  "& .ivrFlowActionTabContainer": {
    display: "flex",
    fontFamily: "Poppins",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    padding: "8px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    overflow: "auto",
    "& .tabContainer": {
      padding: "0",
      "& .tabBox": {
        display: "flex",
        height: "100%",
      },
    },
    "& .tabItem": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "48px",
      padding: "16px 8px 0px 8px",
      width: "50%",
    },
    "& .labelAction": {
      color: "#334155",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Poppins",
    },
    "& .underLine": {
      background: "#0099FF",
      borderRadius: "8px 8px 0px 0px",
      height: "4px",
      width: "100%",
    },
    "& .tabItem-disable": {
      "& .underLine": {
        display: "none",
      },
      "& .labelAction": {
        color: "#94A3B8",
      },
    },
  },
  "& .actionContainer": {
    padding: "0",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
  },
  "& .tableHeader": {
    color: "#334155",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "24px",
    maxWidth: "150px",
    textAlign: "center",
  },
  "& .tableRow": {
    color: "#0F172A",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "24px",
    maxWidth: "150px",
    wordBreak: "break-all",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    textAlign: "center",
  },
  "& .actionButton": {
    margin: "0px 12px",
  },
  "& .designButton": {
    padding: "4px 10px",
    whiteSpace: "nowrap",
  },
  "& .download-div": {
    padding: "20px 27px",
    display: "flex",
    alignItems: "center",
    gap: "16px",
    "& .download-text": {
      color: "#0F172A",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "22px",
    },
    "@media (max-width:796px)": {
      flexWrap: "wrap",
    },
    "& .download-btn": {
      borderRadius: "8px",
      background: "#0099FF",
      color: "#FFFFFF",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
      textTransform: "none",
      padding: "10px 68px",
      "& .MuiButton-label": {
        display: "flex",
        gap: "8px",
      },
    },
  },
}));

const SelectDiv = styled(Select)({
  padding: "10px 8px 10px 20px",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  color: "#0F172A",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 400,
  lineHeight: "22px",
  width: "94px",
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
  "& ..MuiSelect-icon": {
    color: "#0F172A",
  },
  "& .MuiInputBase-input": {
    padding: "0",
  },
});
// Customizable Area End
