export const accountProfile = require("../assets/Accountprofile.svg");
export const accountProfileSelected = require("../assets/AccountprofileSelected.svg");
export const addressBook = require("../assets/AddressBook.svg");
export const addressBookSelected = require("../assets/AddressBookSelected.svg");
export const callCalendar = require("../assets/CallCalendar.svg");
export const callCalendarSelected = require("../assets/CallCalendarSelected.svg");
export const click2Call = require("../assets/Click2Call.svg");
export const click2CallSelected = require("../assets/Click2CallSelected.svg");
export const notification = require("../assets/Notification.svg");
export const notificationSelected = require("../assets/NotificationSelected.svg");
export const campaign = require("../assets/campaign.svg");
export const campaignSelected = require("../assets/campaignSelected.svg");
export const ivrFlow = require("../assets/ivrFlow.svg");
export const ivrFlowSelected = require("../assets/ivrFlowSelected.svg");
