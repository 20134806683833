export const amazon = require("../assets/Amazon.svg");
export const atlassian = require("../assets/Atlassian.svg");
export const canon = require("../assets/Canon.svg");
export const google = require("../assets/Google.svg");
export const walmart = require("../assets/Walmart.svg");
export const customer = require("../assets/customer.png");
export const customerSelected = require("../assets/customer-selected.png");
export const grow = require("../assets/grow.png");
export const growSelected = require("../assets/grow-selected.png");
export const support = require("../assets/support.png");
export const supportSelected = require("../assets/support-selected.png");
export const asana = require("../assets/asana.png");
export const hubspot = require("../assets/hubspot.png");
export const intercom = require("../assets/intercom.png");
export const logo = require("../assets/logo.png");
export const logo1 = require("../assets/logo-1.png");
export const logo2 = require("../assets/logo-2.png");
export const meet = require("../assets/meet.png");
export const teams = require("../assets/teams.png");
export const zendesk = require("../assets/zendesk.png");
export const zendeskIcon = require("../assets/zendesk-icon.png");
export const zoom = require("../assets/zoom.png");
export const rightArrow = require("../assets/right-arrow.svg");
export const rightArrowBlack = require("../assets/right-arrow-black.svg");
export const image = require("../assets/image.png");
export const image1 = require("../assets/image-1.png");
export const image2 = require("../assets/image-2.svg");
export const image3 = require("../assets/image-3.svg");
export const image4 = require("../assets/image-4.svg");
export const image5 = require("../assets/image-5.svg");
export const image6 = require("../assets/image-6.svg");
export const headingImage = require("../assets/heading-image.png");
export const shape = require("../assets/Shape.png");
export const companyLogo = require("../assets/company-logo.png");
export const socialMedia = require("../assets/social-media.png");
export const downArrow = require("../assets/down-arrow.svg");
export const languageIcon = require("../assets/language-icon.svg");
export const marketingImage = require("../assets/marketing-image.png");
export const salesImage = require("../assets/sales-image.png");
export const serviceImage = require("../assets/service-image.png");
export const rightIcon = require("../assets/right-icon.png");
export const facebook = require("../assets/Facebook.svg");
export const linkedin = require("../assets/Linkedin.svg");
export const twitter = require("../assets/Twitter.svg");
export const defaultAvatar = require("../assets/Default-Avatar.png");