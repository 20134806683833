// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Button,
  styled,
  InputAdornment,
  Container,
} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CampaignController, {
  Props,
  images,
  configJSON,
  TTabIndex,
} from "./CampaignController";
import Layout from "../../../components/src/Layout.web";
import { InputBox, StyledCheckbox } from "../../groups2/src/Agents.web";
import Loader from "../../../components/src/Loader.web";
import DeleteConfirmationDialog from "../../../components/src/DeleteConfirmationDialog.web";

const TAB_ACTION: {
  id: TTabIndex;
  label: string;
}[] = [
  {
    id: "inboundIVR",
    label: "Inbound IVR",
  },
  {
    id: "outboundIVR",
    label: "Outbound IVR",
  },
];
// Customizable Area End

export default class Campaign extends CampaignController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Layout menuItem="campaign" navigation={this.props.navigation}>
        <Loader loading={this.state.isLoading} />
        <MainContainer data-test-id="campaignComponent">
          <Box
            className="campaignHeader"
            style={{ background: this.state.selectedMode ? "white" : "" }}
          >
            <Box className="headerBox">
              <img src={images.campaign} width={64} height={48} />
              <Typography className="campaignHeaderTitle">
                {configJSON.campaign}
              </Typography>
              <InputBox
                placeholder="Search"
                type="text"
                data-test-id="searchText"
                startAdornment={
                  <InputAdornment
                    position="start"
                    style={{ zIndex: 1, marginRight: 0 }}
                  >
                    <SearchRoundedIcon className="search" />
                  </InputAdornment>
                }
                value={this.state.search}
                onChange={(event: { target: { value: string } }) =>
                  this.handleSearch(event.target.value)
                }
              />
            </Box>
            {this.state.selectedMode ? (
              <Box style={{ display: "flex" }}>
                <Box
                  style={{
                    display: "flex",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                >
                  <StyledCheckbox
                    data-test-id={"select_all"}
                    checked={this.state.selectAll}
                    onChange={(event, checked) =>
                      this.handleSelectAll(!checked)
                    }
                  />
                  <Box
                    data-test-id={"select_all_text"}
                    onClick={() => this.handleSelectAll(this.state.selectAll)}
                    style={{
                      fontWeight: 700,
                      color: "rgba(100, 116, 139, 1)",
                      fontSize: "16px",
                      cursor: "pointer",
                      paddingLeft: "7px",
                    }}
                  >
                    {!this.state.selectAll
                      ? configJSON.selectAll
                      : configJSON.unselectAll}
                  </Box>
                </Box>
                {this.state.selectedIds.length > 0 ? (
                  <Box
                    data-test-id="select_delete"
                    onClick={this.handleBulkDeleteConfirmationDialog}
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      fontFamily: "Poppins",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                    <DeleteOutlineRoundedIcon
                      style={{
                        color: "rgba(220, 38, 38, 1)",
                        marginRight: "5px",
                      }}
                    />
                    <Box
                      style={{
                        fontWeight: 700,
                        color: "rgba(220, 38, 38, 1)",
                        fontSize: "16px",
                      }}
                    >
                      {configJSON.delete}
                    </Box>
                  </Box>
                ) : null}
                <Box
                  data-test-id="cancel_all"
                  style={{
                    display: "flex",
                    fontFamily: "Poppins",
                    cursor: "pointer",
                    fontWeight: 700,
                    marginLeft: "20px",
                    color: "rgba(217, 119, 6, 1)",
                    fontSize: "16px",
                  }}
                  onClick={() => this.handleSelectOption()}
                >
                  {configJSON.cancel}
                </Box>
              </Box>
            ) : null}
            <Box display="flex" gridGap={30}>
              <Button
                data-test-id="createIVR"
                variant="contained"
                className="campaignHeaderButton"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() =>
                  this.state.tabIndexValue === "inboundIVR" &&
                  this.goToNavigation("AddInBoundIVR")
                }
                disabled={this.state.selectedMode}
              >
                {this.state.tabIndexValue === "inboundIVR"
                  ? configJSON.createInboundIVR
                  : configJSON.createOutboundIVR}
              </Button>
            </Box>
          </Box>
          <Box className="campaignActionTabContainer">
            <Container maxWidth="xl" className="tabContainer">
              <Box className="tabBox">
                {TAB_ACTION.map((tabAction) => (
                  <Box
                    key={tabAction.id}
                    className={`tabItem ${
                      tabAction.id !== this.state.tabIndexValue
                        ? "tabItem-disable"
                        : ""
                    }`}
                    onClick={() => this.handleChangeTab(tabAction.id)}
                    data-test-id="tabItem"
                  >
                    <Typography component="h3" className="labelAction">
                      {tabAction.label}
                    </Typography>
                    <Box className="underLine" />
                  </Box>
                ))}
              </Box>
            </Container>
            <Container className="actionContainer">
              {this.state.tabIndexValue === "inboundIVR"
                ? this.renderInBoundView()
                : this.renderNoData()}
            </Container>
          </Box>
        </MainContainer>
        <DeleteConfirmationDialog
          open={this.state.bulkDeleteConfirmation}
          onDone={this.handleAllDelete}
          onClose={this.handleBulkDeleteConfirmationDialog}
        />
        <DeleteConfirmationDialog
          open={!!this.state.deleteConfirmationId}
          onDone={() =>
            this.handleDeleteCampaign(this.state.deleteConfirmationId)
          }
          onClose={() => this.handleDeleteConfirmationDialog(0)}
        />
      </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(() => ({
  gap: "20px",
  display: "grid",
  "& .borderNone": {
    border: "none",
  },
  "& .headerBox": {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap",
  },
  "& .campaignHeader": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 24,
    padding: "5px",
  },
  "& .campaignHeaderTitle": {
    color: "#281D19",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "32px",
  },
  "& .campaignHeaderButton": {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    backgroundColor: "#0099FF",
    borderColor: "#0099FF",
    borderRadius: 8,
    padding: "10px 16px",
    "&:disabled": {
      color: "#64748B",
      backgroundColor: "#F1F5F9",
      borderColor: "#F1F5F9",
    },
  },
  "& .campaignDataContainer": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  "& .campaignNoDataInnerContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: 10,
    margin: 50,
  },
  "& .noDataImage": {
    marginBottom: 30,
    width: 238,
    height: 232,
  },
  "& .campaignNoDataMainText": {
    fontWeight: 700,
    fontSize: 16,
  },
  "& .campaignNoDataSubText": {
    fontSize: 14,
  },
  "& .campaignActionTabContainer": {
    display: "flex",
    fontFamily: "Poppins",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    padding: "8px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    overflow: "auto",
    "& .tabContainer": {
      padding: "0",
      "& .tabBox": {
        display: "flex",
        height: "100%",
      },
    },
    "& .tabItem": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",
      height: "48px",
      padding: "16px 8px 0px 8px",
      width: "50%",
    },
    "& .labelAction": {
      color: "#334155",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Poppins",
    },
    "& .underLine": {
      background: "#0099FF",
      borderRadius: "8px 8px 0px 0px",
      height: "4px",
      width: "100%",
    },
    "& .tabItem-disable": {
      "& .underLine": {
        display: "none",
      },
      "& .labelAction": {
        color: "#94A3B8",
      },
    },
  },
  "& .actionContainer": {
    padding: "0",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
  },
  "& .tableHeader": {
    color: "#334155",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "22px",
    maxWidth: "150px",
  },
  "& .tableRow": {
    color: "#0F172A",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "22px",
    maxWidth: "150px",
    wordBreak: "break-all",
  },
}));
// Customizable Area End
