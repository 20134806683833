// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  styled,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import HeaderLanding from "../../dashboard/src/HeaderLanding.web";

import BlogListingController, {
  Props,
  images,
} from "./BlogListingController.web";
import Footer from "../../landingpage/src/Footer.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class BlogListing extends BlogListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div id="myref">
        <HeaderLanding
          navigation={this.props.navigation}
          style={{ background: "#ffffff" }}
        />
        <MainContainer data-test-id="main-blogListing-page">
          <IconButton
            data-test-id="back-button"
            className="arrow-back"
            onClick={() => this.goToScreen("LandingPage")}
          >
            <img src={images.arrowBack} />
          </IconButton>
          <div className="title">Blog</div>
          <div className="search-box">
            <TextField
              data-test-id="search-blog-textfield"
              id="search-blog-textfield"
              placeholder="Search Blog"
              fullWidth
              value={this.state.searchText}
              onChange={this.handleSearchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="tag-list">
            {this.state.blogCategoryResponse.map((tag, i) => (
              <Button
                data-test-id="tog-button"
                key={tag.id}
                variant={tag.isSelected ? "contained" : "outlined"}
                onClick={() => this.handleTagClick(i)}
              >
                {tag.name}
              </Button>
            ))}
          </div>
          {!this.state.isLoading ? (
            this.state.blogListingResponse.length ? (
              <>
                <div className="blog-listing">
                  {this.state.blogListingResponse.map((blog) => (
                    <div key={blog.id} className="blog-div">
                      <div className="blog-text">{blog.category_name}</div>
                      <div className="blog-img">
                        <img src={blog.images_and_videos} />
                      </div>
                      <Button
                        data-test-id="blog-heading"
                        className="blog-text-1"
                        onClick={() => this.goToScreen("BlogDetails", blog.id)}
                      >
                        {blog.name}
                      </Button>
                      <div className="blog-text-3">
                        {blog.description_palin_text.substring(0, 130)}
                        ...
                      </div>
                      <div className="blog-text-2">
                        {blog.created_at}, <span> by {blog.author_name}</span>
                      </div>
                    </div>
                  ))}
                </div>
                {this.state.totalPage > 1 ? (
                  <div className="pagination-div">
                    <Pagination
                      data-test-id="pagination"
                      count={this.state.totalPage}
                      variant="outlined"
                      color="primary"
                      siblingCount={0}
                      page={this.state.pageNumber}
                      onChange={(_, page) => this.getBlogListingData(page)}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div className="no-result-found">
                <img src={images.noResultFound} />
                <div className="no-result-text">No Result found</div>
                <div className="no-result-text-1">
                  No Blog matched your Criteria. Try Searching for something
                  else.
                </div>
              </div>
            )
          ) : (
            <Loader loading={this.state.isLoading} />
          )}
        </MainContainer>
        <Footer />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding: "0 80px 50px",
  "& .arrow-back": {
    cursor: "pointer",
    padding: "0",
  },
  "& .title": {
    color: "#000000",
    fontFamily: "Satoshi",
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "72px",
    letterSpacing: "-1px",
  },
  "& .search-box": {
    marginTop: "20px",
    maxWidth: "474px",
    "& .MuiFormControl-root": {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      padding: "20px 8px",
    },
    "& .MuiInput-underline:before": {
      content: "none",
    },
    "& .MuiInput-underline:after": {
      content: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#94A3B8",
    },
    "& .MuiInputBase-root": {
      color: "#0F172A",
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "26px",
    },
    "& .MuiInputBase-input": {
      padding: "0",
    },
  },
  "& .tag-list": {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    marginTop: "25px",
    "& .MuiButton-root": {
      textTransform: "none",
      color: "#131212",
      fontFamily: "Outfit",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "25px",
      padding: "10px 20px",
      borderRadius: "8px",
      border: "2px solid #D2CFD0",
    },
    "& .MuiButton-contained": {
      background: "#0099FF",
      color: "#FFFFFF",
      border: "2px solid #0099FF",
      boxShadow: "none",
    },
  },
  "& .blog-listing": {
    marginTop: "38px",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "30px",
    rowGap: "40px",
    "& .blog-div": {
      width: "calc(50% - 15px)",
      "& .blog-text": {
        color: "#787878",
        fontSize: "14px",
        fontFamily: "Satoshi",
        fontWeight: "700",
        lineHeight: "18.9px",
        textTransform: "uppercase",
        letterSpacing: "1.40px",
        paddingBottom: "16px",
        marginBottom: "16px",
        borderBottom: "1px solid #D2CFD0",
      },
      "& .blog-img": {
        marginBottom: "16px",
        maxWidth: "625px",
        maxHeight: "371px",
        overflow: "hidden",
        "& img": {
          width: "100%",
        },
      },
      "& .blog-text-1": {
        color: "#131212",
        fontSize: "30px",
        fontFamily: "Outfit",
        fontWeight: "500",
        lineHeight: "39.6px",
        cursor: "pointer",
        padding: "0",
        textTransform: "none",
        textAlign: "left",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "& .blog-text-3": {
        color: "#605A5B",
        fontSize: "20px",
        fontFamily: "Outfit",
        fontWeight: "500",
        lineHeight: "28.8px",
        margin: "16px 0",
      },
      "& .blog-text-2": {
        color: "black",
        fontSize: "14px",
        fontFamily: "Satoshi",
        fontWeight: "500",
        lineHeight: "19.6px",
        "& span": {
          color: "#787878",
        },
      },
    },
  },
  "& .pagination-div": {
    marginTop: "100px",
    display: "flex",
    justifyContent: "center",
    "& .MuiPaginationItem-root": {
      zoom: "0.7",
      width: "60px",
      height: "60px",
      borderRadius: "50%",
      color: "#8B8587",
      fontFamily: "Outfit",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "34.4px",
      margin: "0 12px",
    },
    "& .MuiPaginationItem-outlined": {
      border: "2px solid #8B8587",
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected": {
      background: "#0099FF",
      border: "2px solid #0099FF",
      color: "#FFFFFF",
    },
    "& .MuiPaginationItem-icon": {
      fontSize: "43px",
    },
  },
  "& .no-result-found": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "64px",
    "& .no-result-text": {
      color: "black",
      fontFamily: "Satoshi",
      fontSize: "46px",
      fontWeight: 700,
      lineHeight: "66px",
      marginTop: "50px",
    },
    "& .no-result-text-1": {
      color: "#3B3A40",
      fontFamily: "Satoshi",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "26px",
      marginTop: "10px",
    },
  },
  "@media (max-width:959px)": {
    "& .pagination-div": {
      "& .MuiPaginationItem-root": {
        zoom: "1 !important",
        width: "32px !important",
        height: "32px !important",
        fontSize: "16px !important",
        lineHeight: "26.4px !important",
        margin: "0 6px !important",
      },
      "& .MuiPaginationItem-icon": {
        fontSize: "25px !important",
      },
    },
    "& .title": {
      fontSize: "46px !important",
      lineHeight: "70px !important",
    },
    "& .search-box": {
      "& .MuiInputBase-root": {
        fontSize: "16px !important",
        lineHeight: "24px !important",
      },
    },
    "& .tag-list": {
      "& .MuiButton-root": {
        fontSize: "14px !important",
        lineHeight: "23px !important",
      },
    },
    "& .blog-listing": {
      "& .blog-div": {
        "& .blog-text-1": {
          fontSize: "28px !important",
          lineHeight: "37.6px !important",
        },
        "& .blog-text-3": {
          fontSize: "18px !important",
          lineHeight: "26.8px !important",
        },
      },
    },
    "& .no-result-found": {
      "& .no-result-text": {
        fontSize: "44px !important",
        lineHeight: "64px !important",
      },
      "& .no-result-text-1": {
        fontSize: "14px !important",
        lineHeight: "24px !important",
      },
    },
  },
  "@media (max-width:600px)": {
    padding: "0 25px 25px",
    "& .blog-listing": {
      "& .blog-div": {
        width: "auto !important",
      },
    },
  },
});
// Customizable Area End
