import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import createRequestMessage from "../../fileattachment/src/Helpers/create-request-message";
import { expireTokenHandling } from "../../../components/src/Utilities";

// Customizable Area Start

export type Agent = {
  id: string
  type: string
  attributes: {
    id: number
    agent_name: string
    full_phone_number: number
    alt_phone_number: number
    extension: string
    email: string
    call_priority: string
    username: string
    agent_mode: string[]
    company_id: number
    isselected:boolean,
    password_digest: string
    departments: string[]
  }
}

type AgentsData = {
  agents: {
    data: Agent[];
  };
  current_page: number;
  search:boolean;
  total_pages: number;
  total_agents: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  agentsData: AgentsData;
  selectedAgent?: Agent;
  perPage: number;
  selectedMode: boolean;
  selectAll:boolean;
  isLoading: boolean;
  selectedId: string[];
  bulkDeleteConfirmation: boolean;
  deleteConfirmationId: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AgentsController extends BlockComponent<
  Props,
  S,
  SS
  > {
  // Customizable Area Start
  getAllAgents = "";
  deleteAgentApiId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      agentsData: {
        agents: {
          data: [],
        },
        current_page: 1,
        total_pages: 1,
        total_agents: 0,
        search: false,
      },
      selectedId: [],
      selectedMode: false,
      selectAll:false,
      bulkDeleteConfirmation: false,
      deleteConfirmationId: "",

      perPage: 10,
      isLoading: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    // Customizable Area Start
    const token = await getStorageData("authToken")
    if (typeof token !== "string" || token === "") {
      const msg = new Message(
        getName(MessageEnum.NavigationNavigate)
      )
      msg.addData(
        getName(MessageEnum.NavigationNavigateData),
        {
          route: "LandingPage",
        }
      )
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      )
      this.send(msg)
    } else {
      // this.setState(prev => ({ ...prev, isLoading: true }))
      await this.loadAgentsAPI()
    }
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const id = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const successData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      expireTokenHandling(successData)
      if (id === this.getAllAgents) {
        this.handleLoadAgents(successData)
      } else if (id === this.deleteAgentApiId) {
        await this.handleAgentDeleted()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  loadAgentsAPI = async (page: number = 1,search:string = "") => {
    this.setState({ isLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Token: await getStorageData("authToken"),
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllAgents = requestMessage.messageId;
    createRequestMessage({
      requestMessage,
      endPoint: configJSON.getAgentsEndpoint+`?page=${page}&per_page=${this.state.perPage}&query=${search}`,
      method: configJSON.validationApiMethodType,
      header: header,
    });
  }

  handleLoadAgents = (data: AgentsData) => {
    if (data.agents) {
      const updatedData: AgentsData = {
        ...data,
        agents: {
          ...data.agents,
          data: data.agents.data.map(agent => ({
            ...agent,
            attributes: {
              ...agent.attributes,
              isselected: false 
            }
          }))

        }
      }

      this.setState(prev => ({ ...prev, agentsData: updatedData, isLoading: false }))
    }
  }

  handlePageChange = async (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    this.setState({ selectedMode: false,selectAll:false })
    await this.loadAgentsAPI(page + 1)
  }

  handleRowPerPageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prev => ({
      ...prev,
      perPage: +event.target.value,
      selectedMode: false,
      selectAll:false
    }))
    await this.loadAgentsAPI()
  };
  updateSelectedData = (id: any) => {
    const newAgentData = this.state.agentsData.agents.data.find(agent => agent.id === id);
    if (newAgentData) {
      newAgentData.attributes.isselected = !newAgentData.attributes.isselected;
    }
    const updateddata = {
      ...this.state.agentsData,
      managers: {
        ...this.state.agentsData.agents,
        data: [...this.state.agentsData.agents.data]
      }
    };
    this.setState({ agentsData: updateddata })
  this.handleSelectId()
  }
  handleSelectId=()=>
  {
    const filteredData = this.state.agentsData.agents.data.filter(agent => agent.attributes.isselected);
    const ids = filteredData.map(agent => agent.id);
    this.setState({ selectedId: ids }, () => this.checkAllSelected())

  }

  checkAllSelected=()=>
  {
    if(this.state.selectedId.length==this.state.agentsData.agents.data.length)
    {
      this.setState({selectAll:true })
    }
    else
    {
      this.setState({selectAll:false })
    }
  }

  handleAddAgent = () => {
    const msg = new Message(
      getName(MessageEnum.NavigationNavigate)
    )
    msg.addData(
      getName(MessageEnum.NavigationNavigateData),
      {
        route: "AgentAction",
      }
    )
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )

    this.send(msg)
  }

  handleDeleteAgent = async (id:string) => {
    this.setState({ isLoading: true, deleteConfirmationId: "" })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.deleteAgentApiId = requestMessage.messageId
    createRequestMessage({
      requestMessage,
      endPoint: `${configJSON.getAgentsEndpoint}/destroy?agent_ids=[${id}]`,
      method: configJSON.deleteAPIMethod,
      token: await getStorageData("authToken"),
    })
  }

  handleBulkDeleteConfirmationDialog = () => {
    this.setState({ bulkDeleteConfirmation: !this.state.bulkDeleteConfirmation })
  }

  handleDeleteConfirmationDialog = (agentId: string) => {
    this.setState({ deleteConfirmationId: agentId })
  }

  handleBulkDelete=async()=>
  {
    this.setState({ isLoading: true, bulkDeleteConfirmation: false })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.deleteAgentApiId = requestMessage.messageId
    createRequestMessage({
      requestMessage,
      endPoint: `${configJSON.getAgentsEndpoint}/destroy?agent_ids=[${this.state.selectedId}]`,
      method: configJSON.deleteAPIMethod,
      token: await getStorageData("authToken"),
    })
    this.handleSelect()
  }

  handleSearch =async (value:string)=>
  {
    if(value.length==0)
    {
      await this.loadAgentsAPI()
    }
    else if (value.length > 2)
    {
      await this.loadAgentsAPI(1,value)
    }
  }

  handleAgentDeleted = async () => {
    await this.loadAgentsAPI()
  }
  handleSelect = () => {
    this.setState({ selectedMode: !this.state.selectedMode,selectAll:false },()=>this.handleSelectAlldelete(false))
  }
  handleEditAgent = (data:Agent) => {
  
       this.setState(prev => ({ ...prev, selectedAgent: data }))

    const msg = new Message(
      getName(MessageEnum.NavigationMessage)
    )
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
        "EditAgent",
    )
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
     this.props
    )
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData("editAgent",data);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
   
     this.send(msg)
  
    return true;

  }

  handleSelectAll=(checked:boolean)=>
  {
    this.setState({selectAll:!checked},()=>this.handleSelectAlldelete(this.state.selectAll))
  }
  handleSelectAlldelete=(selectall:boolean)=>
  {
    const updatedDataWithoutIds = {
      ...this.state.agentsData,
      agents: {
        ...this.state.agentsData.agents,
        data: this.state.agentsData.agents.data.map(agent => ({
          ...agent,
          attributes: {
            ...agent.attributes,
            isselected: selectall,
          }
        }))
      }
  }
  this.setState({agentsData:updatedDataWithoutIds},()=>  this.handleSelectId())

  }

  // Customizable Area End
}
